export const personalInputs = [
  {
    name: 'name',
    type: 'text',
    title: 'Nombre',
    placeholder: 'Tu nombre',
    span: '',
    options: [],
    showAlways: true
  },
  {
    name: 'surname',
    type: 'text',
    title: 'Apellido',
    placeholder: "Tu apellido",
    span: '',
    options: [],
    showAlways: true
  },
  {
    name: 'gender',
    type: '',
    title: 'Sexo biológico',
    span: '',
    options: [{label: 'Femenino', value: "FEMALE"}, {label: 'Masculino', value: "MALE"}],
    showAlways: true
  },
  {
    name: 'birthDate',
    type: 'date',
    title: 'Fecha de nacimiento',
    span: '',
    options: [],
    showAlways: true
  },

  {
    name: 'mail',
    type: 'email',
    title: 'Email',
    placeholder: "ejemplo@mail.com",
    span: '',
    options: [],
    showAlways: true
  },
]

export const personalLoggedInputs = [
  {
    name: 'name',
    type: 'text',
    title: 'Nombre',
    placeholder: 'Tu nombre',
    span: '',
    options: [],
    disabled: true
  },
  {
    name: 'surname',
    type: 'text',
    title: 'Apellido',
    placeholder: "Tu apellido",
    span: '',
    options: [],
    disabled: true
  },
  {
    name: 'idType',
    type: '',
    title: 'Tipo de documento',
    span: '',
    options: [
      {value: "DNI", label: "DNI"},
      {value: "PAS", label: "Pasaporte"},
      {value: "CI", label: "Cédula"},
    ],
    disabled: true
  },
  {
    name: 'idNumber',
    type: 'text',
    title: 'Número de documento',
    placeholder: "Ej: 40328218",
    span: '',
    options: [],
    disabled: true
  },
  {
    name: 'gender',
    type: '',
    title: 'Sexo biológico',
    span: '',
    options: [{label: 'Femenino', value: "FEMALE"}, {label: 'Masculino', value: "MALE"}],
    disabled: true
  },
  {
    name: 'birthDate',
    type: 'date',
    title: 'Fecha de nacimiento',
    span: '',
    options: [],
    disabled: true
  },

  {
    name: 'mail',
    type: 'email',
    title: 'Email',
    placeholder: "ejemplo@mail.com",
    span: '',
    options: [],
    disabled: false
  },
]

export const professionalsInputs = [
  {
    name: 'name',
    type: 'text',
    title: 'Nombre',
    placeholder: 'Tu nombre',
    span: '',
    options: [],
    showAlways: true
  },
  {
    name: 'surname',
    type: 'text',
    title: 'Apellido',
    placeholder: "Tu apellido",
    span: '',
    options: [],
    showAlways: true
  },
  {
    name: 'gender',
    type: '',
    title: 'Sexo biológico',
    span: '',
    options: [{label: 'Femenino', value: "FEMALE"}, {label: 'Masculino', value: "MALE"}],
    showAlways: true
  },
  {
    name: 'birthDate',
    type: 'date',
    title: 'Fecha de nacimiento',
    span: '',
    options: [],
    showAlways: true
  },
  {
    name: 'idType',
    type: '',
    title: 'Tipo de documento',
    span: '',
    options: [
      {value: "DNI", label: "DNI"},
      {value: "PAS", label: "Pasaporte"},
      {value: "CI", label: "Cédula"},
    ],
    showAlways: true
  },
  {
    name: 'idNumber',
    type: 'text',
    title: 'Número de documento',
    placeholder: "Ej: 40328218",
    span: '',
    options: [],
    showAlways: true
  },

  {
    name: 'mp',
    type: 'text',
    title: 'Matrícula provincial',
    placeholder: 'Ej: 104523',
    span: '',
    options: [],
    showAlways: true
  },
  {
    name: 'mn',
    type: 'text',
    title: 'Matrícula nacional',
    placeholder: 'Ej: 104523',
    span: '',
    options: [],
    showAlways: true
  },
  {
    name: 'specialty',
    type: '',
    title: 'Especialidad',
    placeholder: 'Ej: Extraccionista',
    span: '',
    options: [
      {
        label: "Alergista",
        value: "Alergista"
      }, 
      {
        label: "Cardiólogía",
        value: "Cardiólogía"
      }, 
      {
        label: "Cirugía",
        value: "Cirugía"
      }, 
      {
        label: "Medicina Clínica",
        value: "Medicina Clínica"
      }, 
      {
        label: "Dermatología",
        value: "Dermatología"
      }, 
      {
        label: "Endocrinología",
        value: "Endocrinología"
      }, 
      {
        label: "Gastroenterología",
        value: "Gastroenterología"
      }, 
      {
        label: "Ginecología",
        value: "Ginecología"
      }, 
      {
        label: "Obstreticia",
        value: "Obstreticia"
      }, 
      {
        label: "Hematología",
        value: "Hematología"
      }, 
      {
        label: "Infectología",
        value: "Infectología"
      }, 
      {
        label: "Nefrología",
        value: "Nefrología"
      }, 
      {
        label: "Neurología",
        value: "Neurología"
      }, 
      {
        label: "Nutrición",
        value: "Nutrición"
      }, 
      {
        label: "Oncología",
        value: "Oncología"
      }, 
      {
        label: "Oftalmología",
        value: "Oftalmología"
      }, 
      {
        label: "Pediatría",
        value: "Pediatría"
      }, 
      {
        label: "Traumatología",
        value: "Traumatología"
      }, 
      {
        label: "Urología",
        value: "Urología"
      }, 
      {
        label: "Otra especialidad",
        value: "Otra"
      }, 
    ],
    showAlways: true
  },

  {
    name: 'mail',
    type: 'email',
    title: 'Email',
    placeholder: "ejemplo@mail.com",
    span: '',
    options: [],
    showAlways: true
  },
]

export const addressInputs = [
  {
    name: 'streetName',
    type: 'text',
    title: 'Calle',
    placeholder: 'Ej: 14',
    span: '',
    options: [],
    disabled: false
  },
  {
    name: 'streetNumber',
    type: 'text',
    title: 'Altura',
    placeholder: 'Ej: 205',
    span: '',
    options: [],
    disabled: false
  },
  {
    name: 'streetDescription',
    type: 'text',
    title: 'Entre calles',
    placeholder: 'Ej: entre calle 15 y calle 19',
    span: '',
    options: [],
    disabled: false
  },
  // {
  //   name: 'city',
  //   type: 'text',
  //   title: 'Ciudad',
  //   placeholder: 'La Plata',
  //   span: '',
  //   options: [],
  //   disabled: true
  // },
]

export const localities = [
  "Villa Elisa",
  "Tolosa",
  "Ensenada",
  "City Bell",
  "Ringuelet",
  "Berisso",
  "Gonnet",
  "Los Hornos",
  "Altos de San Lorenzo",
  "La Plata",
  "San Carlos"
].sort((a, b) => a.localeCompare(b))