import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, Link, Outlet } from "react-router-dom"
import { FooterWithSteps } from "../components/Footer"
import Header from "../components/Header"
import Loader from "../components/Loader"
import othersActions from "../redux/actions/others"
import { IndexModal } from "../components/Modals"
import { useAgenda } from "../routes/Agenda/customHooks"

export const AppLayout = () => {
  const isLoading = useSelector(state => state.others.isLoading)
  const dispatch = useDispatch()
  const isModalUserData = useSelector(state => state.others.isModalUserData)
  const setModalUserData = (value) => dispatch(othersActions.setModalUserData(value))
  const [showUserInfo, setShowUserInfo] = useState(false)
  const data = {isModalUserData, setModalUserData, showUserInfo, setShowUserInfo}
  return (
    <div className="min-h-screen flex flex-col">
      {isLoading && <div className='absolute'><Loader /></div>}
      <Header data={{...data}} />
      <div className="min-h-[90vh]"><Outlet /></div>
    </div>
    
    )
  }
  
export const ScheduleLayout = () => {
  const generalData = useAgenda();
  const [showIndexModal, setShowIndexModal] = useState(false);
  const user = useSelector(state => state.auth.user);
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the current location is "/agenda"
  const isAgendaPage = location.pathname === "/agenda";

  // Show the modal only on the "/agenda" page
  useEffect(() => {
    if (isAgendaPage) {
      setShowIndexModal(true);
    } else {
      setShowIndexModal(false);
    }
  }, [isAgendaPage]);

  return (
    <>
      <main className="lg:min-h-[77vh] pb-8 lg:pb-0 px-3 sm:px-6 md:px-9 w-full pt-10">
        <Outlet context={{ ...generalData }} />
      </main>
      <div className="w-full flex justify-center lg:justify-end lg:h-[12vh]">
        <FooterWithSteps steps />
      </div>
      {showIndexModal && (
        <IndexModal
          title="ATENCIÓN"
          onClose={() => {
            setShowIndexModal(false);
            navigate('/agenda');
          }}
          details={"Es necesario concurrir a la extracción con el DNI y la orden original."}
        />
      )}
    </>
  );
}
