import { useRef  } from 'react'
import useOnClickOutside from '../libs/useOnClickOutside';

const DivOutside = ({children, classes, click, onClose}) => {
  const ref = useRef();
  useOnClickOutside(ref, onClose);

  return (
    <div className={classes} ref={ref} onClick={click ? click : null}>
      {children}
    </div>
  )
}

export default DivOutside
