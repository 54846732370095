import { useState } from "react"
import { shopsBasicData } from "../../../data/shops"

const Shops = ({data}) => {
  const {appointment, updateAppointment} = data
  const examShops = (appointment?.test?.shops) ? appointment?.test?.shops : []

  const Shop = ({data: {appointment, searchDates, examShop}}) => {
    const styles = {
      disabled: 'bg-lightGrey  cursor-not-allowed',
      chosen: 'text-white bg-darkBlue shadow-2 pointer-events-none',
      nochosen: 'text-darkBlue bg-white shadow-2'
    }

    const [showPopup, setShowPopup] = useState(false)

    const foundShop = shopsBasicData.find(shop => examShop.value.startsWith(shop.value) )
    const shop = foundShop ? {...foundShop, ...examShop} : false
    const isChosen = appointment?.shop?.value === shop.value 

    const chooseShop = () => {
      if(shop) {
        searchDates(shop?.value)
        updateAppointment({shop})
      }
    }

    const Popup = () =>(
      <div className='absolute  text-xs flex bg-lightGrey text-grey rounded-lg h-[100%] items-center justify-center w-full'>
        <p>No disponible para este estudio</p>
      </div>
    )


    const PopupProximamente = () =>(
      <div className='absolute  uppercase flex bg-lightGrey  rounded-lg h-[100%] items-center justify-center w-full'>
        <p>YA ESTAMOS ATENDIENDO</p>
      </div>
    )

    return (
      <>
        {shop &&
          <div onMouseEnter={() => setShowPopup(true)} onMouseLeave={() => setShowPopup(false)}>
            <button 
              disabled={shop?.disabled}
              onClick={chooseShop}
              className={`flex-1 relative rounded-lg w-full h-full ${shop.disabled ?  styles.disabled : isChosen ? styles.chosen : styles.nochosen}`}
            >
              {(showPopup && shop?.disabled) && <Popup />}
              {(showPopup && shop?.proximamente) && <PopupProximamente />}
              <div className='text-left uppercase flex flex-col justify-center items-center w-full py-2 h-full'>
                <p className='text-sm sm:text-[15px]'>{shop?.label}</p>
                <p className='text-[10px] mt-1 flex items-center gap-[2px]'>{shop?.place?.address}<span className="hidden 400:block">{shop?.place?.city && `- ${shop?.place?.city}`}</span></p>
              </div>
            </button>
          </div>
        }
      </>
    )
  }

  return (
    <div className='grid grid-cols-1 gap-6'>
      <div className='grid lg:grid-cols-4 sm:grid-cols-1  gap-6 w-full'>
        {examShops.map((shop, index) => <Shop data={{...data, examShop: shop}} key={index} /> )}
      </div>
      <div></div>
    </div>
  )
}

export default Shops
