import { useProfessionalRegister } from './hooks'
import {LoaderIcon} from '../../components/Icons'
import Inputs from './Inputs'
import Footer from '../../components/Footer'

const ProfessionalRegister = () => {
  const {formik, twilioError, isSaving, msg, user} = useProfessionalRegister()
  return (
    <div className='w-screen flex flex-col items-center pt-10 gap-10 px-3 sm:px-6'>
      <h1 className='font-bold text-xl sm:text-[28px] xl:text-4xl'>
        Registro para <span className='text-cyan'>profesionales</span>
      </h1>
      {!user && <form onSubmit={formik.handleSubmit} className='bg-white rounded-lg w-full md:w-[700px] shadow-2 p-4 md:p-7'>
        {isSaving ? (
          <div className='w-full h-full flex items-center justify-center h-[300px]'>
            <LoaderIcon color="w-40" />
          </div>
        ) : (
          <>
            <h4 className='font-bold uppercase text-lg'>Completá tus datos</h4>
            <p className="text-deactivatedDarkBlue text-[14px] sm:text-lg -mt-1">
              Es obligatorio definir al menos una <span className="font-bold">matrícula</span> 
            </p>
            <Inputs data={{formik, twilioError}} />
            <div className='w-full flex flex-col pt-10'>
              <p className={`mb-3 -mt-2 text-left ${msg.color}`}>{msg.text}</p>
              <button
                type="submit"
                className='w-full text-white bg-cyan shadow-6 py-4 px-12 rounded-lg flex-1 md:flex-0'
              >
                Registrarme
              </button>
            </div>
          </>
        )}
      </form>}
      {user && (
        <h4 className='font-bold uppercase text-lg'>
          Hay una cuenta logueada, si querés registrar un nuevo profesional cerrá sesión
        </h4>
      )}
      <Footer />
    </div>
  )
}

export default ProfessionalRegister
