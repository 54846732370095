import axios from "axios"
import { useState } from "react"
import toast from "react-hot-toast"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { cleanPhone } from "../../libs/cleanData"
import { parseJwt } from "../../libs/jwt"
import authActions from "../../redux/actions/auth"
import othersActions from "../../redux/actions/others"
import { MaterialIcon } from "../Icons"

export const useLogin = (setShowLogin, setShowUserInfo, defaults = {idType: "DNI", idNumber: ""}, path) => {
  const [document, setDocument] = useState({...defaults, error: ""})
  const [tokens, setTokens] = useState({step1: "", step2: "", step3: ""})
  const [codeError, setCodeError] = useState(false)
  const [passError, setPassError] = useState("")
  const [currentUser, setCurrentUser] = useState({})
  const [step, setStep] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const setModalUserData = (value) => dispatch(othersActions.setModalUserData(value))

  const steps = ["Ingresá tu documento para iniciar sesión", "Te enviaremos un código para identificarte", "Ingresá el código que recibiste"]
  const currentStep = steps[step]

  const BE_URL = process.env.REACT_APP_JAVA_BE_URL

  const endpoints = {
    prelogin: `${BE_URL}/external/api/v1/security/prelogin`,
    sendCode: `${BE_URL}/external/api/v1/security/sendCode`,
    verifyCode: `${BE_URL}/external/api/v1/security/verifyCode`,
    users: `${BE_URL}/external/api/v1/users`,
    plainLogin: `${BE_URL}/external/api/v1/security/plainlogin`,
  }

  const searchUserByDocument = (e) => {
    e.preventDefault()
    setIsLoading(true)

    const payload = {idType: document.idType, idNumber: document.idNumber}

    axios.post(endpoints.prelogin, payload)
      .then(res => {
        if(res.data && res.data !== "") {
          setTokens({ ...tokens, step1: res.data })
          setCurrentUser(parseJwt(res.data))
          setDocument({...document, error: ""})
          setStep(step+1)
          setIsLoading(false)
        } else {
          throw new Error("No estás en nuestra base de datos, por favor agendá un turno primero.")
        }
      })
      .catch(error => {
        console.log(error);
        setDocument({...document, error: "No estás en nuestra base de datos, por favor agendá un turno primero."})
        setIsLoading(false)
      })
 }

  const sendCode = (method) => {
    setIsLoading(true)

    const payload = {
      type: method,
      idType: currentUser.idType,
      idNumber: currentUser.idNumber,
      sender: currentUser.email,
      phoneNumber: `${currentUser.phone}`
    }

    axios.post(endpoints.sendCode, payload, { headers: { AuthSendCode: tokens.step1 }})
      .then(res => {
        setTokens({...tokens, step2: res.data})
        setStep(step+1)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false)
      })
  }

  const cheers = [
    "Es bueno verte otra vez",
    "Bienvenido al portal de turnos",
    "Estás de vuelta",
    "Que dicha tenerte por aquí"
  ]

  const searchUser = (userId, token) => {
    axios.get(`${endpoints.users}/${userId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => {
        if(res.data.id) {
          setCodeError("")
          const newPhone = cleanPhone(res.data.phone)

          dispatch(authActions.authUser({
            ...res.data,
            gender: res.data.gender ? res.data.gender.toUpperCase() : "",
            birthDate: res?.data?.birthDate ? new Date(res?.data?.birthDate) : "",
            phone: newPhone,
            token
          }))

          setShowUserInfo && setShowUserInfo(false)

          toast({
            type: "info",
            title: `¡${cheers[Math.floor(Math.random()*4)]}, ${res.data?.name}!`,
            icon: <MaterialIcon icon="waving_hand" />
          })

          setModalUserData(res.data.oldUser)
          setShowLogin && setShowLogin(false)
          if(!setShowLogin) navigate(path ? path : location?.state?.from?.pathname ? location?.state?.from?.pathname : "/agenda")
        }
          setIsLoading(false)
      })
      .catch(error => {
        console.log(error);
        setCodeError("Hubo un error, intentalo nuevamente")
        setIsLoading(false)

        if(error.response && error.response.status === 401) dispatch(authActions.signOut(true))
      })
  }   

  const sendPassword = (password) => {
    setIsLoading(true)

    const payload = { password, dni: currentUser.idNumber }

    axios.post(endpoints.plainLogin, payload)
      .then(res => {
        if(res.data) {
          const token_data = parseJwt(res.data)
          searchUser(token_data.userId, res.data)
          setPassError("")
        } else throw new Error("no-pass")
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false)
        setPassError((error?.response?.status === 403) ? "Contraseña incorrecta" : (error.message === "no-pass") ? "No se pudo validar la contraseña, ingresá con otro método" :  "Hubo un error, vuelve a intentarlo en unos minutos o ingresá con otro método")
      })
  }     

  const validateCode = (inputCode) => {
    setIsLoading(true)

    const payload = { token: tokens.step2, code: inputCode }

    axios.post(endpoints.verifyCode, payload)
      .then(res => {
        searchUser(currentUser.id, res.data)
      })
      .catch(error => {
        console.log(error);
        setCodeError(true)
        setIsLoading(false)
      })
  }

  return {
    setDocument, 
    document, 
    searchUserByDocument, 
    step, 
    currentStep, 
    sendCode, 
    validateCode, 
    setStep, 
    currentUser, 
    codeError,
    sendPassword,
    passError,
    isLoading,
  }
}
