import DatePicker from "react-datepicker"
import PhoneInput from "react-phone-number-input"
import { personalLoggedInputs } from "../../../data/inputs"
import "react-datepicker/dist/react-datepicker.css";
import { CustomBirthDateInput } from "../../../components/Inputs";

const InputsPatient = ({data: {formik}}) => {
  const {setFieldValue} = formik

  const handlePhone = (value) => setFieldValue("phone", value || "")

  const handleBirthDate = (value) => {
    setFieldValue("birthDate", (value && value !== null) ? value : "")
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-6'>
      {personalLoggedInputs.map((input, i) => (
        <div key={i} className={`${input.span}`}>
          <div>
            {input.options.length < 1
              ? (input?.name === "birthDate") 
                ? (
                  <CustomBirthDateInput data={{input, formik, handleBirthDate, disabled: input.disabled && formik.values[input.name]}} />
                ) : (
                  <fieldset className={`h-14 flex flex-col gap-1 bg-green-10 ${input.span}`}>
                    <span className='text-xs opacity-70'>{input.title}</span>
                    <input 
                      className={`pb-1 outline-none bg-red-10 text-darkBlue placeholder:text-[#16325C99] min-h-[24px]`}
                      type={input.type}
                      placeholder={input.placeholder}
                      name={input.name}
                      onChange={formik.handleChange}
                      value={formik.values[input.name]}
                      disabled={(input.disabled && formik.values[input.name])}
                    />
                    <div className={`flex flex-1 items-center text-xs text-red-400 bg-blue-10 border-t pt-1 ${formik.errors[input.name] ? 'border-error' : ''} opacity-80`}>
                      {formik.errors[input.name] && formik.errors[input.name]}
                    </div>
                  </fieldset>
                )
              : (
                <fieldset className={`h-14 flex flex-col gap-1 bg-green-10 ${input.span}`}>
                  <span className='text-xs opacity-70'>{input.title}</span>
                  <select 
                    name={input.name}
                    onChange={formik.handleChange}
                    value={formik.values[input.name]}
                    className={`pb-1 outline-none w-full text-darkBlue`}
                    disabled={(input.disabled && formik.values[input.name])}
                  >
                    <option value="">Seleccionar</option>
                    {input.options.map((o, i) => <option key={i} value={o.value}>{o.label}</option>)}
                  </select>
                  <div className={`flex flex-1 items-center text-xs text-red-400 bg-blue-10 border-t ${formik.errors[input.name] ? 'border-error' : ''} opacity-80`}>
                    {formik.errors[input.name] && formik.errors[input.name]}
                  </div>
                </fieldset>
              )
            }
          </div>
        </div>
      ))}
      <fieldset className={`h-20 flex flex-col gap-1 bg-green-10`}>
        <span className='text-xs opacity-70'>Número celular</span>
        <div className='min-h-[24px]'>
          <PhoneInput
            international
            defaultCountry="AR"
            onChange={handlePhone}
            value={formik.values["phone"]}
            placeholder="Cód. de área + Núm. Celular"
            autoComplete={"false"}
            numberInputProps={{
              placeholder: "Cód. de área + Núm. Celular",
              style: {
                boxShadow: "0 0 #0000",
                border: "none",
                outline: "none",
                background: "transparent",
                marginLeft: "-25px",
              },
            }}
          />
        </div>
        <span className={`text-xs opacity-60  border-t pt-1 ${formik.errors["phone"] ? 'border-error' : ''}`}>
          Sin el 0 y sin el 15
        </span>
        <div className={`flex flex-1 items-center text-xs text-red-400 bg-blue-10 opacity-80`}>
          {formik.errors["phone"] && formik.errors["phone"]}
        </div>
      </fieldset>
    </div>
  )
}

export default InputsPatient
