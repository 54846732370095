import axios from "axios"
import { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import authActions from "../../redux/actions/auth"


export const useAppoList = () => {
    const dispatch = useDispatch()
    const [auditorias, setAuditorias] = useState([])
    const [isSearching, setIsSearching] = useState(false)
    
    
    const {REACT_APP_JAVA_BE_URL} = process.env
    const user = useSelector(state => state.auth.user)
    
    const SearchAuditorias = useCallback(() => {
        let numeroDoc = user?.idNumber
        const url = `${REACT_APP_JAVA_BE_URL}/external/api/v1/faba/practicasAutorizadas/${numeroDoc}`
        setIsSearching(true)
      
        axios.get(url)
          .then(res => {
            if(res.data) {
                setAuditorias(res.data)
            } else throw new Error("without-auditorias")
            setIsSearching(false)
          })
          .catch(error => {
            console.log(error); 
            setIsSearching(false)
            if(error.response && error.response.status === 401) dispatch(authActions.signOut(true))
          })
      }, [REACT_APP_JAVA_BE_URL, user?.idNumber, dispatch])

  useEffect(() => {
    SearchAuditorias()
  }, [SearchAuditorias])

  return {
    auditorias,
    isSearching,
    user,
  }
}