import moment from 'moment';
import Calendar from 'react-calendar';

const CalendarBox = ({data: {appointment, dates, updateAppointment, searchRangesByDay }}) => {
  const defineNavigationLabel = ({date}) => `${moment(date).format('MMMM')}`
  const defineShortWeekday = (_, value) => ['D', 'L', 'M', 'M', 'J', 'V', 'S'][value.getDay()]
  const disableTiles = ({_, date}) => {
    const todayDate = moment(new Date(date)).format('YYYY-MM-DD')
    return date.getDay() === 0 || !dates.find(dateDB => dateDB.date === todayDate)
  }

  const handleChange = (date) => {
    updateAppointment({date: new Date(date)})
    searchRangesByDay(new Date(date), appointment?.shop?.value)
  }

  const ArrowsCalendar = ({left}) => (
    <div className=" h-full flex items-center justify-center" style={{zIndex: 199}}>
      <svg className={`${left ? 'rotate-180' : 'rotate-0'} fill-[#16325C]`} width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M1.49755 15C1.17714 15 0.856579 14.8954 0.6124 14.6861C0.123259 14.2676 0.123259 13.5895 0.6124 13.171L7.24201 7.49992L0.6124 1.82898C0.123259 1.41045 0.123259 0.73243 0.6124 0.313899C1.10154 -0.104633 1.89395 -0.104633 2.38309 0.313899L9.89629 6.74254C10.3854 7.16107 10.3854 7.83909 9.89629 8.25763L2.38309 14.6863C2.1393 14.8962 1.81842 15 1.49755 15Z"/>
      </svg>
    </div>
  )

  return (
    <div className='max-w-full h-[100%] z-50'>
      <Calendar 
        className="px-4 pb-3"
        locale="es-AR"
        minDetail="month"
        
        minDate={new Date()}
        nextLabel={<ArrowsCalendar />}
        prevLabel={<ArrowsCalendar left />}
        prev2Label={null}
        next2Label={null}
        defaultActiveStartDate={new Date(appointment?.date)}
        value={new Date(appointment?.date)} 

        onChange={handleChange}
        navigationLabel={defineNavigationLabel}
        formatShortWeekday={defineShortWeekday}
        tileDisabled={disableTiles}
      />
    </div>
  )
}

export default CalendarBox
