import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoaderIcon, MaterialIcon } from '../../../components/Icons'
import othersActions from '../../../redux/actions/others'
import { ArrowCalendar } from '../AppointmentSchedule'
import Form from './Form'
import { usePatientForm } from './hook'
import InputsPatient from './InputsPatient'
import LoggedInputs from './LoggedInputs'
import Resume from './Resume'

const PatientForm = () => {
  const hookData = usePatientForm()

  const dispatch = useDispatch()
  const setModalUserData = () => dispatch(othersActions.setModalUserData(true))

  const {
    appointment, 
    formik,
    user, 
    loggedSubmit,
    documentValidation
  } = hookData

  const isDomi = appointment?.shop?.value.includes('DOMI')

  const hour = appointment?.hour?.hour ? appointment?.hour?.hour : "00"
  const minute = appointment?.hour?.minute ? appointment?.hour?.minute : "00"
  
  const preDate = moment(new Date(appointment?.date))
  const date = `${preDate.format('dddd D')} de ${preDate.format('MMMM')}`
  const time = isDomi ? `Entre ${hour}:${minute} am y ${parseInt(hour)+4}:${minute} am` : `${hour}:${minute} hs`
  const type = appointment?.shop.value


  return (
    <div className='mx-auto max-w-max md:pb-10 lg:pb-0'>
      <div className='mb-4'>
        <Link to="/agenda/calendario" className='flex items-center justify-start gap-2 max-w-max'>
          <ArrowCalendar left color="fill-cyan h-3" /> <p className='text-md font-bold text-cyan opacity-60'>Volver</p>
        </Link>
        <h1 className='font-bold text-xl sm:text-[28px] xl:text-4xl mt-2'>
          Completá tus <span className='text-cyan'>datos personales:</span>
        </h1>
        
      </div>
      <div className='flex gap-4 flex-col lg:flex-row w-full'>
        <Resume data={{
          date,
          location: isDomi ? "En tu domicilio" : appointment?.shop?.value.includes('SUC13') ? 'Av. 13 N° 215 - La Plata' :appointment?.shop?.value.includes('SUC14') ? 'Av. 14 N° 280 - La Plata':'Calle 485 N° 4685 Esq. 138',
          test: appointment?.test?.name,
          time,
          type
        }} />

        <form 
          className='flex-1 min-w-ful md:max-w-mi lg:min-w-[700px] md:max-w-[700px] space-y-4' 
          onSubmit={formik.handleSubmit}
        >
          {/* DATOS PERSONALES */}
          <div className=' bg-white shadow-2 rounded-lg py-5 px-5 md:px-7'>
            <h4 className='font-bold uppercase text-lg mb-1'>{user ? '' : 'Completá'} tus datos</h4>

            {documentValidation?.loading ? <LoaderIcon /> : user 
              ? <LoggedInputs data={{user, appointment}} /> 
              : <Form data={hookData} />}

          </div>

          <div className='w-full flex md:justify-between gap-4 pb-5 md:pb-10'>
              <Link
                to="/agenda/calendario"
                className='w-full lg:max-w-max border border-cyan text-cyan py-4 px-6 400:px-12 rounded-lg flex-1 opacity-70 md:flex-0 text-center'
              >
                Volver
              </Link>
              {(!user && !documentValidation.exist && documentValidation.searched)
                ? (
                  <button
                    type="submit"
                    className='w-full lg:max-w-max text-white bg-cyan shadow-6 py-4 px-6 400:px-12 rounded-lg flex-1 md:flex-0'
                  >
                    Continuar
                  </button>
                )
                : (user) && (
                  <button
                    type="button"
                    onClick={(user?.gender && user?.birthDate) ? loggedSubmit : setModalUserData}
                    className='w-full lg:max-w-max text-white bg-cyan shadow-6 py-4 px-6 400:px-12 rounded-lg flex-1 md:flex-0'
                  >
                    {(user?.gender && user?.birthDate) ? "Continuar" : "Actualizar datos"}
                  </button>
                )            
              }
            </div>
        </form>
      </div>
    </div>
  )
}

export default PatientForm
