import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { LoaderIcon } from '../../components/Icons'
import { LoginBox } from '../../components/Login'
import { useLogin } from '../../components/Login/hook'

const LoginPage = () => {
  const user = useSelector(state => state.auth.user)
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  // const [defaults, setDefaults] = useState({idType: "DNI", idNumber: ""})

  const path = location?.search ? location?.search.replace('?', '') : location?.state?.from?.pathname ? location?.state?.from?.pathname : "/agenda"

  useEffect(() => {
    if(user) {
      navigate(location?.state?.from ? location?.state?.from : "/agenda")
    }
  }, [location])

  const hasDocument = params.idType && params.idNumber 

  const defaults = hasDocument ? {idNumber: params.idNumber, idType: params.idType} : {idNumber: "", idType: "DNI"}

  const data = useLogin(null, null, defaults, path)
  const { step, currentStep, isLoading } = data

  return (
    <div className='flex items-center justify-center px-3'>
      <div className='flex flex-col gap-10 items-center pt-10  max-w-[500px]'>
        <div className='text-center'>
          <h2 className='text-2xl md:text-3xl font-bold'>
            {user ? 'Iniciaste' : 'Ingresá en tu'} <span className='text-cyan'>{user ? 'sesión' : 'cuenta'}</span>
          </h2>
          <p className='opacity-60 mt-2 px-[1px]'>
            {user ? 'Si querés ingresar con otro usuario cerrá la sesión actual' : 'Podrás agendar turnos, gestionarlos y descargar tus resultados cuando estén disponibles'}
          </p>
        </div>
        {!user && <div className=' w-full bg-darkBlue p-6 rounded-lg'>
          {isLoading 
            ? <LoaderIcon color='fill-white w-40' />
            : <LoginBox data={{step, data, currentStep, isPage: true}} />
          }
        </div>}
      </div>
    </div>
  )
}

export default LoginPage
