import { MaterialIcon } from '../../../components/Icons'

const Resume = ({data: {test, location, date, time, type}}) => {
  return (
    <div className='bg-darkBlue rounded-lg shadow-2 items-start flex flex-col md:flex-row lg:flex-col gap-2 text-white text-sm px-3 py-4 min-w-[250px] h-min'>
      <div className='flex items-center gap-1'>
        <MaterialIcon icon="vaccines" extrastyles="text-[18px]" />
        <p>{test}</p>
      </div>
      <div className='flex items-center gap-1'>
        <MaterialIcon icon="place" extrastyles="text-[18px]" />
        <p>{location}</p>
      </div>
      {type !== "DOMI_EXTRACCION" ? (
        <div>
          <div className='flex items-center gap-1'>
          <MaterialIcon icon="event_available" extrastyles="text-[18px]" />
          <p>{date}</p>
          </div>
          <div className='flex items-center gap-1'>
            <MaterialIcon icon="schedule" extrastyles="text-[18px]" />
            <p>{time}</p>
          </div>
        </div>
      ) : (
        <div>
          <div className='flex items-center gap-1'>
          <MaterialIcon icon="event_available" extrastyles="text-[18px]" />
          <p>A definir.</p>
          </div>
        </div>
      )}
      
    </div>
  )
}

export default Resume