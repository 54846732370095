import moment from "moment"
import { useEffect, useState } from "react"
import { MaterialIcon } from "../../components/Icons"

export const ResultsTable = ({data: {results, role, downloadPDF}}) => {
  const [resultsToShow, setResultsToShow] = useState([...results])
  // const [pages, setPages] = useState({quantity: Math.ceil(results.length/10), current: 1, results: 10})
  // const [conditions, setConditions] = useState({ canNextPage: false, canPrevPage: false })

  const lessThan10 = results.length < 11
  const withoutResults =  results.length === 0

  // console.log(resultsToShow);

  // useEffect(() => {
  //   setResultsToShow(results.filter((r, i) => i < 10))
  // }, [])

  // useEffect(() => {
  //   setConditions({
  //     canNextPage: pages.current < pages.quantity,
  //     canPrevPage: pages.current > 1
  //   })

  //   console.log(results.slice(0, pages))

  // }, [pages.current])

  return (
    <div className='bg-white shadow-5 md:w-[760px] lg:w-[992px] max-w-[992px] rounded-[5px] lg:pt-[54px] lg:px-10 2xl:px-[100px] lg:pb-[70px]'>
      <div className="hidden lg:block">
        <table className='w-full text-lg text-left'>
          <thead className='text-deactivatedDarkBlue'>
            <tr className=''>
              <th className='font-normal pb-5'>Ingreso</th>
              <th className='font-normal pb-5'>Resultado</th>
              <th className='font-normal pb-5'>Fecha</th>
              <th className='font-normal pb-5'>{role === 'doctor' ? 'Paciente' : 'Médico'}</th>
            </tr>
          </thead>
          <tbody className='text-darkBlue'>
            {results.map((result, index) => <ResultRow key={index} data={{downloadPDF, result}} />)}
          </tbody>
        </table>
      </div>
      <div className="lg:hidden">
        {results.map((result, index) => (
          <div key={index} className="flex items-center py-6 w-full justify-between px-6 border-b">
            <div>
              <p className="mb-1">{result.apellidoMedico}, {result.nombreMedico}</p>
              <p className="text-deactivatedDarkBlue">{moment(result.fecha).format('DD/MM/Y')} - N° {result.number} </p>
            </div>
            <button className="px-[10px] py-2 bg-cyan rounded-full" onClick={() => downloadPDF(result.number)}>
              <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.783203 16.1837C0.783203 15.7326 1.14884 15.367 1.59987 15.367H11.3999C11.8509 15.367 12.2165 15.7326 12.2165 16.1837C12.2165 16.6347 11.8509 17.0003 11.3999 17.0003H1.59987C1.14884 17.0003 0.783203 16.6347 0.783203 16.1837Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M6.49987 0.666992C6.9509 0.666992 7.31654 1.03263 7.31654 1.48366V12.917C7.31654 13.368 6.9509 13.7337 6.49987 13.7337C6.04884 13.7337 5.6832 13.368 5.6832 12.917V1.48366C5.6832 1.03263 6.04884 0.666992 6.49987 0.666992Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1.83907 8.25619C2.15799 7.93726 2.67508 7.93726 2.99401 8.25619L6.49987 11.7621L10.0057 8.25619C10.3247 7.93726 10.8417 7.93726 11.1607 8.25619C11.4796 8.57512 11.4796 9.0922 11.1607 9.41113L7.07734 13.4945C6.92419 13.6476 6.71646 13.7337 6.49987 13.7337C6.28328 13.7337 6.07555 13.6476 5.9224 13.4945L1.83907 9.41113C1.52014 9.0922 1.52014 8.57512 1.83907 8.25619Z" fill="white"/>
              </svg>
            </button>
          </div>
        ))}
      </div>
      {withoutResults && <p className='text-center pt-10 text-deactivatedDarkBlue'>No tienes resultados disponibles aún</p>}
    </div>
  )
}

const ResultRow = ({data: {downloadPDF, result}}) => {

  return (
    <tr className='border-t border-lightGrey'>
      <td className='py-2'>{result.number}</td>
      <td className=''>
        <button 
          className='bg-cyan text-white py-2 px-8 rounded-[5px] shadow-8 text-sm my-4' 
          onClick={() => downloadPDF(result.number)}
        >
          Descargar
        </button>
      </td>
      <td className=''>{moment(result.fecha).format('DD/MM/Y')}</td>
      <td className='text-deactivatedDarkBlue'>{result.apellidoMedico}, {result.nombreMedico}</td>
    </tr>
  )
}

      {/* <div className='w-full flex-1 flex justify-end pt-5 border-t border-lightGrey'>
        <p>current: {pages.current}</p>
        <div className='flex items-center gap-3'>
          <p className='text-deactivatedDarkBlue'>Filas por página</p>
          <select 
            value={resultsPerPage}
            onChange={(e) => setPages({...pages, quantity:  Math.ceil(results.length/e.target.value), results: e.target.value})}
            disabled={lessThan10} 
            className={`${lessThan10 ? 'text-deactivatedDarkBlue' : ''}`}
          >
            <option>10</option>
            {results.length > 10 && <option>20</option>}
            {results.length > 20 && <option>30</option>}
          </select>
          <div className='flex'>
            <button 
              onClick={() => setPages({...pages, current: pages.current - 1})}
              disabled={!conditions.canPrevPage || lessThan10} 
              className={`${!conditions.canPrevPage ? 'text-deactivatedDarkBlue' : ''}`}
            >
              <MaterialIcon icon="keyboard_arrow_left" />
            </button>
            <button 
              onClick={() => setPages({...pages, current: pages.current + 1})}
              disabled={!conditions.canNextPage || lessThan10} 
              className={`${!conditions.canNextPage ? 'text-deactivatedDarkBlue' : ''}`}
            >
              <MaterialIcon icon="keyboard_arrow_right" />
            </button>
          </div>
        </div>
      </div> */}