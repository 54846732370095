import { useLogin } from './hook'
import { AuthIcon, LoaderIcon } from '../Icons';
import Document from './DocumentPopup';
import Methods from './MethodsPopup';
import VerifyCodePopup from './VerifyCodePopup';
import DivOutside from '../DivOutside';

const Login = ({data: {setShowLogin, showUserInfo, setShowUserInfo}}) => {
  const data = useLogin(setShowLogin, setShowUserInfo)
  const { step, currentStep, isLoading } = data

  return (
    <DivOutside 
      onClose={() => setShowLogin(false)}
      classes='bg-darkBlue rounded-lg absolute top-[170%] z-100 -right-[22px] 400:right-0 shadow-2 w-[316px] max-w-[316px] min-w-[316px] 500:w-[350px] 500:max-w-[350px] 500:min-w-[350px] z-[100]'
    >
      {isLoading ? <LoaderIcon color='fill-white w-40' />
      : (
        <div className='bottom-arrow h-[100%] w-full p-3 500:p-5'>
          <div className='space-y-5'>
            {step !== 1 && <div className='flex items-start gap-2'>
              <div className=''><AuthIcon /></div>
              <p className='text-white font-bold text-[20px] tracking-1'>{currentStep}</p>
            </div>}
            {step === 0 && <Document data={{...data}} />}
            {step === 1 && <Methods data={{...data}} />}
            {step === 2 && <VerifyCodePopup data={{...data}} />}
          </div>
      </div>
      )}
    </DivOutside>
  )
}

export const LoginBox = ({data: {step, data, currentStep, isPage}}) => {
  return (
    <div className='space-y-5'>
      {step !== 1 && <div className='flex items-start gap-2'>
        <div className=''><AuthIcon /></div>
        <p className='text-white font-bold text-[20px] tracking-1'>{currentStep}</p>
      </div>}
      {step === 0 && <Document data={{...data, isPage}} />}
      {step === 1 && <Methods data={{...data}} />}
      {step === 2 && <VerifyCodePopup data={{...data}} />}
    </div>
  )
}

export default Login
