export const DefaultToast = ({title, description, onClose, onSubmit, type, children, icon}) => {
  const bg = type === "error" ? 'bg-red-100 border-red-300 text-red-400' : (type === "success") ? 'bg-green-100 border-green-300 text-green-400' : (type === "info") ? 'bg-blue-100 border-blue-300 text-blue-400' : (type === "warning") ? 'bg-orange-100 border-orange-300 text-orange-400' :'bg-white'
  return (
    <div className={`shadow-2 rounded-lg border-2 py-5 px-3 sm:px-3 md:px-6 flex items-center max-w-max gap-3 md:min-w-max ${bg} `}>
      {icon}
      <div className={`flex flex-col gap-1`}>
        <p className={``}>{title}</p>
        <p className={`text-sm opacity-80 max-w-[500px]`}>{description ? description : ''}</p>
      </div>
    </div>
  )
}
