const asyncReduce = (arr,mapper,initial) => arr.reduce(async (p, item, idx) => {
  const acc = await p;
  const result = await mapper(acc, item, idx, arr);
  return result;
}, Promise.resolve(initial));

const asyncMap = ( array, mapper ) => asyncReduce(
  array,
  async (acc, item, idx) => {
    const result = await mapper(item, idx, array);
    return [...acc, result];
  },
  []
);

const asyncFlatMap = (arr,mapper) => asyncReduce(
  arr,
  async (acc, item, idx) => {
    const result = await mapper(item, idx, arr);
    if (Array.isArray(result)) return [...acc, ...result];
    return [...acc, result];
  },
  []
);

const asyncFind = (arr,finder) => arr.reduce(async (p, item, idx) => {
  const lastResult = await p;
  if (lastResult) return lastResult;
  const currResult = await finder(item, idx, arr);
  if (currResult) return item;
  return;
}, Promise.resolve());

const asyncFilter = async (arr,fn) => {
  const mapped = await asyncMap(arr, async (item, idx, arr) => {
    const result = await fn(item, idx, arr);
    return { result, item }
  })
  return mapped.filter((r) => r.result).map((r) => r.item);
}

export const async = {
  map: asyncMap,
  flatMap: asyncFlatMap,
  reduce: asyncReduce,
  // parallelMap(arr, mapper) => Promise.all(arr.map(mapper)),
  filter: asyncFilter,
  find: asyncFind,
};
