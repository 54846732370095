import { ResultsTable } from './table'
import { usePatientsResultsList } from './hook'
import { MaterialIcon } from '../../components/Icons'
import { EmptyModal, SimpleModal } from '../../components/Modals'
import {BsWhatsapp} from 'react-icons/bs'

const Patients = () => {
  const MAIL_SECRETARIA = process.env.REACT_APP_MAIL_SECRETARIA  
  const data = usePatientsResultsList()
  return (
    <>
      {data?.errorModal.show && (
        <EmptyModal>
          {/* <SimpleModal data={{
            title: data.errorModal.title, 
            description: data.errorModal.text,
            onClose: () => data?.setErrorModal({...data?.errorModal, show: false})
          }} /> */}
          <div className="bg-white p-3 400:p-5 rounded-lg flex flex-col items-center max-w-[500px]">
            <div className='w-full flex items-center justify-end'>
              <button onClick={() => data?.setErrorModal({...data?.errorModal, show: false})}>
                <svg className='text-darkBlue' width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 13L1 1" stroke="grey" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M13 1L1 13" stroke="grey" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </div>
            <MaterialIcon icon="info" extrastyles="text-[50px] 400:text-[70px] text-blue-400" />
            <div  className='py-6 400:text-center flex flex-col items-center gap-2'>
              <p className='text-2xl font-bold text-blue-400'>{data.errorModal.title}</p> 
              <p className='pl-3 400:pl-0 text-blue-300 400:max-w-[400px]'>{data.errorModal.text}</p>
            </div>
            <div className='w-full pl-2 400:pl-6 400:mb-5'>
              <div className='flex flex-col gap-2 text-[15px] text-blue-400 w-full max-w-[400px]'>
                <a 
                  className='flex items-center gap-1 justify-start' 
                  href="https://wa.me/5492216234231" 
                  target="_blank"
                >
                  <span className='text-[19px]'><BsWhatsapp /></span>
                  <span className='underline'>+54 9 221 623 4231</span>
                </a>
                <a 
                  className='flex items-center gap-1 justify-start' 
                  href="5492216234231"
                >
                  <MaterialIcon icon="phone" extrastyles="text-[20px]" />
                  <span className='underline'>+54 9 221 425 4900</span>
                </a>
                <a 
                  className='flex items-center gap-1 justify-start'                  
                  href={`mailto:${MAIL_SECRETARIA}`}
                  target="_blank"
                >
                  <MaterialIcon icon="mail" extrastyles="text-[20px]" />
                  <span className='underline'>secretaria@dagostino-bruno.com.ar</span>
                </a>
                <a 
                  className='flex items-center gap-1 justify-start' 
                  href="https://dagostino-bruno.com.ar" 
                  target="_blank"
                >
                  <MaterialIcon icon="public" extrastyles="text-[20px]" />
                  <span className='underline'>www.dagostino-bruno.com.ar</span>
                </a>
              </div>
            </div>
          </div>
        </EmptyModal>
      )}
      <div className='mx-auto max-w-max pt-10 pb-10 px-3 md:px-6'>
        <h3 className='font-bold text-xl 400:text-[28px] xl:text-4xl 400:mb-8'>
          Hola {data.user.name}, estos son <span className='text-cyan'>los resultados de tus pacientes</span>
        </h3>

        {data.canSearch && <div>
          <div className='flex items-center gap-2 bg-white rounded-lg max-w-[320px] shadow-4 mb-5 px-2'>
            <MaterialIcon icon="search" />
            <input
              placeholder='Buscar paciente por apellido...'
              type="search"
              className='h-[30px] w-full my-2 outline-none border-none'
              onChange={(e) => data.filterResults(e.target.value)}
            />
          </div>
        </div>}
        <ResultsTable data={{...data, role: "doctor"}}/>
      </div>
    </>
  )
}

export default Patients