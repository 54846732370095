import { useDispatch, useSelector } from "react-redux"

import * as Yup from 'yup';
import { useFormik } from 'formik';
import scheduleActions from "../../../redux/actions/schedule";
import othersActions from "../../../redux/actions/others";
import moment from "moment"
import obras from '../AppointmentSchedule/obras.json'
import { useEffect, useState, useRef } from "react";
import axios from "axios"
import toast from "react-hot-toast"

import { cleanPhone } from "../../../libs/cleanData"
import { parseJwt } from "../../../libs/jwt"
import authActions from "../../../redux/actions/auth"
// import { MaterialIcon } from "../../Icons"
import { useParams, useNavigate } from "react-router-dom"
//import { useLogin } from './hookLogin'
import { useSearchParams } from "react-router-dom";
export const useInsuranceFormFromWP = (files, setFiles) => {

  const listObras = obras.data.os 

  //const {tokenId} = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()

  const [searchParams] = useSearchParams();

//searchParams.get('page'); // 10
  const appointment = useSelector(state => state.schedule)
  //const isLoading = useSelector(state => state.others.isLoading)
  
  
  
  const [isParticular, setIsParticular] = useState(0)
  
  
  const healthInsurances = obras.data.os
  .filter(o => o.name.toLocaleLowerCase() !== "particular")
  .sort((a, b) => a.name.localeCompare(b.name))
  
  const particular = obras.data.os[0]
  
  const isDomi = appointment?.shop?.value.includes('DOMI');

  const validarCadenaNoVacia = (message) => {
    return Yup.string().test(
      'is-not-empty',
      message,
      value => value && value.trim() !== ''
    ).required();
  };
  

  const insuranceSchema = Yup.object().shape({
    streetName: !isDomi ? Yup.string() : validarCadenaNoVacia('No olvidés agregar la calle'),
    streetNumber: !isDomi ? Yup.string() : validarCadenaNoVacia('No olvidés agregar la altura'),
    city: !isDomi ? Yup.string() : validarCadenaNoVacia('No olvidés agregar la localidad'),
    streetDescription: Yup.string(),
    healthInsurance: Yup.object().required("No olvidés elegir tu cobertura"),
  });
  
  

  const initialValues = {
      streetName: appointment?.streetName || "",
      streetNumber: appointment?.streetNumber || "",
      streetDescription: appointment?.streetDescription || "",
      city: appointment?.city || "",
      healthInsurance: appointment?.healthInsurance || null,
  };
  
  //const loginData = useLogin(false, true)
  //const token1="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZFR5cGUiOiJETkkiLCJyb2xlIjoiVVNFUl9ST0xfU1RBTkRBUkQiLCJnZW5kZXIiOiJNQUxFIiwibWFpbCI6ImxpYy5lbGlzZW9wYWxhY2lvc0BnbWFpbC5jb20iLCJyZWdpc3RlcmVkRGF0ZSI6MTcwNDA0NDQxMSwic3RyZWV0TnVtYmVyIjoiIiwiY2l0eSI6IiIsImlzcyI6IiM6OkczbjNyQGwtSkBiYjNyOjojIiwicmVjZWl2ZUVtYWlsIjp0cnVlLCJpZE51bWJlciI6IjI3ODIxODQ5IiwiYmlydGhEYXRlIjozMjMxNDY4MDAsInZlcnNpb24iOiIxIiwicmFuZ2VJZCI6MTIyODUyLCJzdHJlZXROYW1lIjoiIiwic2NoZWR1bGVUeXBlIjoiVEVTVF9FWFRSQUNDSU9OIiwicGhvbmUiOiI1NDkyMjE1MDI4NDU1Iiwic3RyZWV0RGVzY3JpcHRpb24iOiIiLCJvbGRVc2VyIjoiZmFsc2UiLCJpZCI6MjgzODEsImlkSGVhbHRoSW5zdXJhbmNlIjoxOCwiZXhwIjoxNzA0MDU4ODI3fQ.roDPVMOouwhgISC9twl3JQaNGhqYs5gPm9ZWw4PXyPI";


  const tokenInput=parseJwt(searchParams.get('token'))

  //console.log(searchParams.get('token'))

  const simulationTokenId={
    user:{
      id: tokenInput.id,//"106651",
      idType: tokenInput.idType, 
      idNumber: tokenInput.idNumber, 
      name: tokenInput.name, 
      surname: tokenInput.surname, 
      birthDate: tokenInput.birthDate, 
      gender: tokenInput.gender, 
      mail: tokenInput.mail, 
      role: tokenInput.role, 
      phone: tokenInput.phone  
    },
    streetName: tokenInput.streetName,
    streetNumber: tokenInput.streetNumber,
    streetDescription: tokenInput.streetDescription,
    city: tokenInput.city,
    test:{
      name:tokenInput.scheduleType == "TEST_EXTRACCION"?"Extracción / muestra" : (tokenInput.scheduleType == "TEST_MICOLOGICO"?"Exámen Micológico":"Hisopados"),
      code:tokenInput.scheduleType
    },
    date: tokenInput.date,
    hour:{
      hour: tokenInput.hour, //falta este dato
      minute: tokenInput.minutes, //falta este dato
      id:tokenInput.rangeId
    },
    scheduleType : tokenInput.scheduleType,
    healthInsurance: {
      id: tokenInput.idHealthInsurance,
      name: tokenInput.nameHealthInsurance 
    },
    shop: {value: tokenInput.shop},
    idRange :  tokenInput.rangeId
  }


  const [document, setDocument] = useState({})

  const [tokens, setTokens] = useState({step1: "", step2: "", step3: ""})
  
  const [currentUser, setCurrentUser] = useState({})
  const [step, setStep] = useState(0)

  const steps = ["Ingresá tu documento para iniciar sesión", "Te enviaremos un código para identificarte", "Ingresá el código que recibiste"]
  
  const currentStep = steps[step]

  const updateAppointment = (info) => dispatch(scheduleActions.updateAppointment(info))
  //const BE_URL = process.env.REACT_APP_JAVA_BE_URL
  const BE_URL = process.env.REACT_APP_JAVA_BE_URL
  
  const endpoints = {
    prelogin: `${BE_URL}/external/api/v1/security/prelogin`,
    sendCode: `${BE_URL}/external/api/v1/security/sendCode`,
    sendCodeToBotMaker: `${BE_URL}/external/api/v1/security/sendCodeToBotMaker`,
    
    verifyCode: `${BE_URL}/external/api/v1/security/verifyCode`,
    users: `${BE_URL}/external/api/v1/users`,
    plainLogin: `${BE_URL}/external/api/v1/security/plainlogin`,
  }





  const searchUser = (userId, token) => {
    axios.get(`${endpoints.users}/${userId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => {
        if(res.data.id) {
          //setCodeError("")
          const newPhone = cleanPhone(res.data.phone)

          dispatch(authActions.authUser({
            ...res.data,
            gender: res.data.gender ? res.data.gender.toUpperCase() : "",
            birthDate: res?.data?.birthDate ? new Date(res?.data?.birthDate) : "",
            phone: newPhone,
            token
          }))

          //setShowUserInfo && setShowUserInfo(false)

          //toast({
          //  type: "info",
          //  title: `¡${cheers[Math.floor(Math.random()*4)]}, ${res.data?.name}!`,
          //  icon: <MaterialIcon icon="waving_hand" />
         // })

          //setModalUserData(res.data.oldUser)
          //setShowLogin && setShowLogin(false)
          //if(!setShowLogin) navigate(path ? path : location?.state?.from?.pathname ? location?.state?.from?.pathname : "/agenda")
        }
          setIsLoading(false)
      })
      .catch(error => {
        console.log(error);
        //setCodeError("Hubo un error, intentalo nuevamente")
        setIsLoading(false)
        
        //if(error.response && error.response.status === 401) dispatch(authActions.signOut(true))
      })
  }   
 
  const searchUserByDocument = () => {
   
    setIsLoading(true)

    const payload = {idType: simulationTokenId.user.idType, idNumber: simulationTokenId.user.idNumber}
    
    //console.log(`Estoy en search user ${simulationTokenId}`);

    axios.post(`${BE_URL}/external/api/v1/security/prelogin`, payload)
      .then(res => {
        if(res.data && res.data !== "") {
          setTokens({ ...tokens, step1: res.data })
          //console.log(parseJwt(res.data));
          
          let user=parseJwt(res.data);

          console.log(user);

          setCurrentUser(parseJwt(res.data))

          setStep(step+1)
          setIsLoading(false)
       

        } else {
          throw new Error("No estás en nuestra base de datos, por favor agendá un turno primero.")
        }
      })
      .catch(error => {
        console.log(error);
        setDocument({...document, error: "No estás en nuestra base de datos, por favor agendá un turno primero."})
        setIsLoading(false)
      })
 }

 

  //const [orders, setOrders] = useState([])

  const withInitial = appointment?.address !== "" && appointment?.healthInsurance?.id !== undefined

  
  const sendCodeToBotMaker = (tokens) => {
    setIsLoading(true)

    const payload = {
      type: 'POST',
      idType: simulationTokenId.user.idType,//currentUser.idType,
      idNumber: simulationTokenId.user.idNumber,
      sender: simulationTokenId.user.mail,
      phoneNumber: `${simulationTokenId.user.phone}`
    }

    //console.log(payload)
    // console.log(tokens)

    axios.post(endpoints.sendCodeToBotMaker, payload, { headers: { AuthSendCode: tokens.step1 }})
      .then(res => {
        setTokens({...tokens, step2: res.data})
        setStep(step+1)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false)
      })
  }




  //const u=parseJwt(user.get('TokenLogApi_DB_PreLogin').split('.')[1]);

  //const URI =  BE_URL+'external/api/v1/security/sendCodeToBotMaker';
  /*const payload = {

    idType: simulationTokenId.user.idType,
    idNumber: simulationTokenId.user.idNumber,
    mail: simulationTokenId.user.email,
    role: simulationTokenId.user.role,
    phone: simulationTokenId.user.phone,
    iss: simulationTokenId.user.iss,
    id: simulationTokenId.user.id,
    exp: simulationTokenId.user.exp

  };*/


  appointment.patient = simulationTokenId.user;

  
  //if(insurance) 
  
  //appointment.hour.id = simulationTokenId.idHour;
  useEffect(() => {
  
    
    // console.log(appointment);
    //appointment.healthInsurance.name= obras.data.os.find(o => o.id === simulationTokenId.healthInsurance.id)

    dispatch(scheduleActions.updateAppointment(simulationTokenId))

    
    //setDocument({idType: simulationTokenId.user.idType, idNumber: simulationTokenId.user.idNumber , error: ""});
    //TODO: simular login. 
    // dispatch(authActions.authUser(simulationTokenId.user))  
    
    if(step===0&&!isLoading) searchUserByDocument();
    if(step===1&&!isLoading) sendCodeToBotMaker(tokens);
    //console.log(`Estoy en el useEffect${currentUser.idNumber}`);
    
    if(step===2&&!isLoading) searchUser(simulationTokenId.user.id,tokens.step2);
   
    
   //console.log('asdasdasd')
   //searchUserByDocument();
 
    //const u=parseJwt(user.get('TokenLogApi_DB_PreLogin').split('.')[1]);
    
   formik.setValues({
      healthInsurance: {id: simulationTokenId.healthInsurance.id},
      streetName: simulationTokenId.streetName,
      streetDescription: simulationTokenId.streetDescription,
      streetNumber: simulationTokenId.streetNumber,
      city: simulationTokenId.city
    })
    
  }, [currentUser,step,isLoading,tokens])

  
 
  //Comentado porque hay error en la forma de la validación
  // const insuranceSchema = Yup.object().shape({
  //   user: Yup.object().shape({
  //     idType: Yup.string().required(''),
  //     idNumber: Yup.number().integer().required(''),
  //     name: Yup.string().required(''),
  //     surname: Yup.string().required(''),
  //     birthDate: Yup.string().required(''),
  //     gender: Yup.string().required(''),
  //     mail: Yup.string().required(''),
  //     role: Yup.string().required(''),
  //     streetName: isDomi ? Yup.string().required('No olvidés agregar la calle') : Yup.string(),
  //     streetNumber: isDomi ? Yup.string().required('No olvidés agregar la altura') : Yup.string(),
  //     city: isDomi ? Yup.string().required('No olvidés agregar la localidad') : Yup.string(),
  //     streetDescription: Yup.string(),
  //     phone: Yup.string().required('')
  //   }),
  //   test: Yup.object().shape({
  //     name: Yup.string().required('')
  //   }),
  //   hour: Yup.object().shape({
  //     hour: Yup.number().integer().required(''),
  //     minute: Yup.number().integer().required(''),
  //   }),
  //   scheduleType: Yup.string().required(''),
  //   healthInsurance: Yup.object().required("No olvidés elegir tu cobertura"),
  // });
  

  const [orders, setOrders] = useState([]);

  

  //const withInitial = appointment?.address !== "" && appointment?.healthInsurance?.id !== undefined

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: insuranceSchema,
    onSubmit: values => {
        console.log('values insurance data', values);
        try {
            const authorizations = values?.orders && values?.orders.length > 0 ? values?.orders.map(order => {
                return { data: order.data, type: order.type };
            }) : [];

            const credential = values?.credential ? [{
                data: values?.credential?.data,
                type: values?.credential?.type
            }] : [];

            dispatch(othersActions.setIsLoading(true));
            setFiles({ authorizations, credential });
            dispatch(scheduleActions.updateAppointment({
                ...appointment,
                healthInsurance: values?.healthInsurance,
                streetName: values?.streetName || "",
                streetNumber: values?.streetNumber || "",
                streetDescription: values?.streetDescription || "",
                city: values?.city || ""
            }));
            dispatch(othersActions.setIsLoading(false));
            navigate('/agenda/confirmacion');
        } catch (error) {
            console.log(error);
        }
    }
});

  const addFileToOrders = (file) => {
    setOrders([...orders, {...file}])
    formik.setFieldValue('orders', [...orders, {...file}])
  }

  const deleteOrder = (orderToDelete) => {
    setOrders(orders.filter(order => order !== orderToDelete))
  }

  const preDate = moment(appointment?.date)
  const date = `${preDate.format('dddd D')} de ${preDate.format('MMMM')} - ${appointment?.hour?.hour}:${appointment?.hour?.minute} hs`

  return { 
    appointment, 
    formik,
    isDomi,
    addFileToOrders,
    orders,
    deleteOrder,
    step,
    searchUserByDocument,
    sendCodeToBotMaker,
    isLoading
  }
}