import React from 'react'
import { MaterialIcon } from '../../../components/Icons'
import imageCompression from 'browser-image-compression';
import {async} from '../../../libs/async'
import { compressFile } from '../../../libs/compressImage';
import { BoxAlert } from '../../../components/Alerts'

const FilesSection = ({data: {formik, addFileToOrders, orders, deleteOrder, files, setFiles}}) => {
  const {values, setFieldValue} = formik
  const labelStyles = 'flex items-center justify-between max-w-[200px] gap-1 opacity-70 border-b shadow-3 bg-neutral-200 rounded-lg py-1 px-4 pl-4 mt-1'

  const deleteCredential = () => setFieldValue("credential", {})

  return (
    <fieldset>
      <legend className='text-sm mb-5 opacity-90'>Presentá documentación</legend>
      <div className='grid lg:grid-cols-2 sm:grid-cols-1'>
      <div className='flex flex-col gap-6'>
        <div>
          <p className='mb-2 opacity-60 text-sm'>Credencial {values.healthInsurance.name}</p>
         {!values?.credential?.name ? (
          <FileUploader data={{formik, name: "credential", files}} />
         )
         : (
          <label className={labelStyles}>
            <p className='text-xs text-ellipsis overflow-hidden'>{values.credential.name}</p>
            <button onClick={deleteCredential}>
              <MaterialIcon extrastyles="text-lg" icon="close" />
            </button>
          </label>
         )}
        </div>
        <div>
          <p className='mb-2 opacity-60 text-sm'>Órdenes médicas</p>
          <div className='flex items-center flex-wrap gap-2'>
            {orders && orders.length > 0 && orders.map((order, index) => {
              const orderName = order.name.length > 30 ? order.name.slice(0, 20) + "..." : order.name
              return (
                <label className={labelStyles} key={index}>
                  <p className='text-xs text-ellipsis overflow-hidden'>{order.name}</p>
                  <button onClick={() => deleteOrder(order)}>
                    <MaterialIcon extrastyles="text-lg" icon="close" />
                  </button>
                </label>
            )})}
          </div>
          {orders.length < 5 && <FilesUploader data={{addFileToOrders, files}} />}
        </div>
      </div>
      <div className='flex flex-col gap-6'>
      <BoxAlert data={{
            type: "warning", 
            description: "Concurrir a la extracción con su DNI y la orden original",
            extraContainerStyles: "!mt-2"
          }} />
        

      </div>
      </div>
    </fieldset>
  )
}

const FileUploader = ({data: {formik, name, files}}) => {
  const {values, setFieldValue} = formik

  const chooseFile = async (e) => {
    const files = await async.map(Array.from(e?.currentTarget?.files), compressFile)
    console.log(files);

    let reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.onload = () => {
      const fileData = reader.result.split(',')[1]

      setFieldValue(name, { 
        data: fileData, 
        type: files[0].type.split('/')[1], 
        name: files[0].name 
      })
    }
  }

  const hasFile = values[name]

  const labelStyles = `flex items-center gap-1 cursor-pointer opacity-70 ${!hasFile ? 'border-[2px] border-dashed' : 'border-b shadow-3 bg-neutral-200'} rounded-lg py-1 px-4 pl-4 mt-1 max-w-max`

  return (
    <label className={labelStyles}>
      <input 
        type="file"
        accept="image/*"
        className='hidden'
        name={name}
        onChange={chooseFile}
      />
      <MaterialIcon icon="attach_file" extrastyles="text-lg" />
      <p className='text-xs'>{'Adjuntar imagen'}</p>
    </label>
  )
}

const FilesUploader = ({data: {addFileToOrders}}) => {

  const chooseFile = async (e) => {
    const files = await async.map(Array.from(e?.currentTarget?.files), compressFile)
    console.log(files);

    let reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.onload = () => {
      const fileData = reader.result.split(',')[1]

      addFileToOrders({ 
        data: fileData, 
        type: files[0].type.split('/')[1], 
        name: files[0].name 
      })
    }
  }

  const labelStyles = 'flex items-center gap-1 cursor-pointer border-[2px] border-dashed opacity-70 border shado bg-neutral- rounded-lg py-1 px-4 pl-4 max-w-max mt-3'

  return (
    <label className={labelStyles}>
      <input 
        type="file"
        accept="image/*"
        className='hidden'
        onChange={chooseFile}
      />
      <MaterialIcon icon="attach_file" extrastyles="text-lg" />
      <p className='text-xs'>Adjuntar imagen</p>
    </label>
  )
}

export default FilesSection