import { LoaderIcon, MaterialIcon } from "./Icons"
import { useUpdateUserInfo } from './Header/hooks'
import InputsPatient from '../routes/Agenda/PatientForm/InputsPatient'
import { useEffect, useState } from "react";

export const EmptyModal = ({children,onClose}) => {



  const handleKeyDown = (event) => {
    console.log('A key was pressed', event.keyCode);
    if (event.keyCode===27) onClose()
   
    
    
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div onClick={onClose}    className="fixed inset-0 flex items-center justify-center bg-modalContainer z-[1000000000000] px-3 md:px-6 lg:px-0">
      {/* className="fixed inset-0 flex items-center justify-center bg-modalContainer z-[1000000000000] px-3 md:px-6 lg:px-0" */}
    
        
      <div className="">
        {children && children}
      </div>
    </div>
  )
}



export const ExclusiveTestsModal = ({title, tests1, tests2, onClose, onNext}) => {


  /*useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        setIsOpen(false)
      }
    }
  
    document.addEventListener('keydown', handleEscapeKey)
    return () => document.removeEventListener('keydown', handleEscapeKey)
  }, [])*/
 
  return (
    <EmptyModal  onClose={onClose}>
      <div className="flex gap-8">
        <div className="bg-cyan items-center justify-center p-10 rounded hidden md:flex">
          <p className="text-[147px] font-bold text-white">!</p>
        </div>


        <div className="rounded lg:w-[782px] w-full max-h-[560px] md:max-h-full overflow-y-scroll md:overflow-y-auto">
          <div className="flex items-center bg-cyan rounded-t overflow-hidden">
            <div className="px-4 h-full md:hidden">
              <p className="text-[45px] font-bold text-white">!</p>
            </div>
            <div className="bg-darkBlue text-white flex items-start text-left md:text-center justify-between gap-2 md:gap-20 px-4 md:px-6 lg:pl-16 py-5 w-full">
              <div className="md:text-center w-full flex md:justify-center">
                <p className="lg:text-[24px]" dangerouslySetInnerHTML={{__html: title}}></p>
              </div>
              <button onClick={onClose}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 13L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M13 1L1 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </div>
          </div>

          <div className="bg-white rounded-b">
            <div className="py-6 lg:pt-9 md:px-10 lg:px-16 lg:pb-16 grid md:grid-cols-2 md:gap-7 lg:gap-11">
              <div>
                {tests1 && tests1.length > 0 && tests1.map((t, i) => (
                  <div key={i} className={`${i === 0 ? 'pb-4' : 'py-4 md:border-t'} parent-modal1 px-4 md:px-0 md:border-[#dee2e8] lg:text-[20px]`}>{t}</div>
                ))}
              </div>
              <div>
                {tests2 && tests2.length > 0 && tests2.map( (t, i) => (
                  <div key={i} className={`${i === 0 ? 'py-4 md:border-t md:border-none md:pb-4 md:pt-0' : 'py-4 md:border-t'} parent-modal2 px-4 md:px-0 md:border-[#dee2e8] lg:text-[20px] md:bg-white`}>{t}</div>
                ))}
              </div>
            </div>
            {onNext && <div className="w-full flex items-center justify-center gap-4 pb-3 px-3 -mt-5">
              <button
                onClick={onClose}
                className='w-full border border-cyan text-cyan py-4 px-6 400:px-12 rounded-lg flex-1 opacity-70 md:flex-0 text-center'
              >
                Cerrar
              </button>
              <button
                onClick={onNext ? onNext : null}
                className='w-full text-white bg-cyan shadow-6 py-4 px-6 400:px-12 rounded-lg flex-1 md:flex-0'
              >
                Continuar
              </button>
            </div>}
          </div>
        </div>
      </div>
    </EmptyModal>
  )

}

export const IndexModal = ({title, details, onClose}) =>{
  return (<EmptyModal  onClose={onClose}>
    <div className="flex gap-8">
      <div className="bg-cyan items-center justify-center p-10 rounded hidden md:flex">
        <p className="text-[147px] font-bold text-white">!</p>
      </div>


      <div className="rounded lg:w-[782px] w-full max-h-[560px] md:max-h-full overflow-y-scroll md:overflow-y-auto">
        <div className="flex items-center bg-cyan rounded-t overflow-hidden">
          <div className="px-4 h-full md:hidden">
            <p className="text-[45px] font-bold text-white">!</p>
          </div>
          <div className="bg-darkBlue text-white flex items-start text-left md:text-center justify-between gap-2 md:gap-20 px-4 md:px-6 lg:pl-16 py-5 w-full">
            <div className="md:text-center w-full flex font-bold md:justify-center">
              <p className="lg:text-[24px]" dangerouslySetInnerHTML={{__html: title}}></p>
            </div>
            <button onClick={onClose}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 13L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13 1L1 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>
        </div>

        <div className="bg-white rounded-b">
          <div className="text-[25px] py-6 lg:pt-9 md:px-10 lg:px-16 lg:pb-16 grid  md:gap-7 lg:gap-11">
            {details}
          </div>
        </div>
      </div>
    </div>
  </EmptyModal>)
}


export const TextModal = ({title, details, onClose}) => {
  return (
    <EmptyModal  onClose={onClose}>
      <div className="flex gap-8">
        <div className="bg-cyan hidden md:flex items-center justify-center p-10 rounded">
          <p className="text-[147px] font-bold text-white">!</p>
        </div>

        <div className="rounded lg:w-[782px] flex-1">
          <div className="flex items-center bg-cyan rounded-t overflow-hidden">
            <div className="px-4 h-full md:hidden">
              <p className="text-[45px] font-bold text-white">!</p>
            </div>
            <div className="bg-darkBlue text-white flex items-start text-left md:text-center justify-between gap-2 md:gap-20 px-4 md:px-6 lg:pl-16 py-5 w-full">
              <div className="md:text-center w-full flex md:justify-center">
                <p className="lg:text-[24px]" dangerouslySetInnerHTML={{__html: title}}></p>
              </div>
              <button onClick={onClose}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 13L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M13 1L1 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </div>
          </div>

          <div className="bg-white rounded-b pt-9 px-6 md:px-10 lg:pl-16 lg:pr-6 pb-16 w-full text-[17px] md:text-[24px]">
            <p>{details}</p>
          </div>
        </div>
      </div>
    </EmptyModal>
  )
}

export const FormUserInfo = ({setModalUserData}) => {
  const {
    formik,
    twilioError,
    isSaving,
    msg
  } = useUpdateUserInfo(setModalUserData)

  return (
    <EmptyModal>
      {!isSaving ? (
        <div className="w-screen 500:w-[500px] rounded-lg md:w-[700px] max-h-[560px] overflow-y-scroll md:overflow-auto">
          <div className="bg-white p-4 sm:p-7 rounded-lg">
            <div className="flex justify-between">
              <div>
                <h2 className="font-bold md:text-[22px] flex items-center gap-1">
                  Modificá tus 
                  <span className="text-cyan flex items-center gap-1">
                    datos 
                    <span className="hidden sm:block">personales</span>
                  </span>
                </h2>
                <p className="text-deactivatedDarkBlue text-[14px] sm:text-lg -mb-2">
                  No olvides <span className="font-bold">guardar tus cambios</span> al finalizar
                </p>
              </div>
              <MaterialIcon icon="close" action={() => setModalUserData(false)} pointer/>
            </div>
            <form className="flex flex-col gap-7 text-darkBlue" onSubmit={formik.handleSubmit}>
              <InputsPatient data={{formik, twilioError}} />
              <div className="">
                <p className={`mb-3 -mt-2 text-right ${msg.color}`}>{msg.text}</p>
                <div className="flex flex-col sm:flex-row justify-end gap-4">
                  <button 
                    onClick={() => setModalUserData(false)} 
                    className="text-deactivatedDarkBlue font-bold text-[17px] order-2 sm:order-1 underline underline-offset-2"
                  >
                    Cancelar
                  </button>
                  <button 
                    type="sumbit"
                    className="py-3 px-5 bg-cyan text-white shadow-1 rounded-lg order-1"
                  >
                    Guardar cambios
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="bg-white w-[300px] h-[300px] rounded-lg shadow-2 flex items-center jusitfy-center">
          <LoaderIcon />
        </div>
      )}
    </EmptyModal>
  )
}

export const SimpleModal = ({data: {title, description, onClose}}) => {
  <div className="bg-white p-5 rounded-lg flex flex-col items-center">
    <div>
      <button onClick={onClose}>X</button>
    </div>
    <MaterialIcon icon="error" />
    <p>{title}</p> 
    <p>{description}</p>
    <div>
      <p>
        Su solicitud está en proceso. Ante cualquier duda comuníquese por alguno de los siguientes medios.
      </p>
      <div>
        <a href="https://wa.me/5492216234231" target="_blank"><MaterialIcon icon="whatsapp" /> +54 9 221 623 4231</a>
      </div>
    </div>
  </div>
}
