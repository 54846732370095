import moment from "moment"
import { useDispatch } from "react-redux"
import { BoxAlert } from "../../../components/Alerts"
import othersActions from "../../../redux/actions/others"

const LoggedInputs = ({data: {user}}) => {
  const dispatch = useDispatch()
  const setModalUserData = () => dispatch(othersActions.setModalUserData(true))

  const inputs = [
    {
      label: "Nombre",
      value: user.name,
    },
    {
      label: "Apellido",
      value: user.surname,
    },
    {
      label: "Tipo",
      value: user.idType,
    },
    {
      label: "Documento",
      value: user.idNumber,
    },
    {
      label: "Fecha de nacimiento",
      value: user.birthDate ? moment(new Date(user.birthDate)).format('DD/MM/Y') : "Por definir",
    },
    {
      label: "Sexo",
      value: user.gender ? (user.gender === "MALE" ? "Masculino" : "Femenino") : "Por definir",
     },
    {
      label: "Email",
      value: user.mail,
    },
    {
      label: "Teléfono",
      value: user.phone,
    },
  ]

  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-2'>
        {inputs.map((input, index) => (
          <div className='w-full text-darkBlue opacity-80 pr-4' key={index}>
            <span className='text-xs opacity-50'>{input.label}</span>
            <input 
              className={`pb-1 outline-none bg-red-10 text-darkBlue w-full border-b-2 border-deactivatedDarkBlue bg-white`}
              value={input.value}
              disabled
            />
          </div>
        ))}
      </div>
      <BoxAlert data={{
        type: "info",
        extraContainerStyles: "mt-4"
      }}>
        {(user?.gender && user?.birthDate) ? (
          <p>
            Si tenés que actualizar algún dato hacé <span className="!underline !cursor-pointer !font-bold text-blue-800" onClick={setModalUserData}>clic aquí</span>
          </p>
        ) : (
          <p>Actualizá tus datos para continuar agendando el turno.</p>
        )}
      </BoxAlert>
    </div>
  )
}

export default LoggedInputs
