import moment from 'moment'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className={`bg-blue-10 flex items-center justify-end gap-4 pb-2 px-3 sm:px-6 md:px-9 w-full mb-2`}>
      <div className='min-w-max flex flex-col items-center xl:items-end'>
        <p className=' sm:text-lg lg:text-[20px] text-deactivatedDarkBlue md:text-darkBlue'>
          ¿Necesitás ayuda? Hacé click <a href="https://api.whatsapp.com/send/?phone=5492216234231&text&app_absent=0" target="_blank" rel="noreferrer" className='font-bold text-cyan'>aquí</a>
        </p>
        <p className='opacity-50 text-[16px] hidden md:block'>
          Sistema de turnos online del <span className='font-bold'>Laboratorio D’Agostino Bruno</span>
        </p>
      </div>
    </footer>
  )
}

export const FooterWithSteps = () => {
  const appointment = useSelector(state => state.schedule)
  const location = useLocation()
  const path = location.pathname
  const params = useParams()

  const steps = [
    {
      label: "Elegir servicio",
      next: path === "/agenda/calendario" || path === "/agenda/paciente" || path === "/agenda/cobertura" || path === "/agenda/confirmacion",
      current: path === "/agenda",
      appoValue: appointment?.test?.name
    },
    {
      label: "Elegir turno",
      next: path === "/agenda/paciente" || path === "/agenda/cobertura" || path === "/agenda/confirmacion",
      current: path === "/agenda/calendario",
      appoValue: moment(appointment?.date).format('DD/MM/Y')
    },
    {
      label: "Datos personales",
      next: path === "/agenda/cobertura" || path === "/agenda/confirmacion",
      current: path === "/agenda/paciente",
      appoValue: false
    },
    {
      label: "Cobertura",
      next: path === "/agenda/confirmacion",
      current: path === "/agenda/cobertura",
      appoValue: appointment?.insurance?.health_insurance
    },
    {
      label: "Confirmación",
      next: false,
      current: path === "/agenda/confirmacion",
      appoValue: false
    }
  ]

  return (
    <footer className={`bg-blue-10 flex flex-col xl:flex-row items-center justify-end xl:justify-between xl:justify-between gap-4 pb-2 lg:pb-0 px-3 sm:px-6 md:px-9 w-full`}>
      {(location.pathname !== `/agenda/confirmacion/${params.tokenId}`) && <div className="flex items-center gap-6 text-[15px] md:pb-6 lg:pb-0">
        {steps.map(({current, label, next, appoValue}, index) => (
          <div className="flex items-center gap-2 min-w-max" key={index}>
            {/* next ? (appoValue ? appoValue : label) : */}
            {(current || next) && <p className='hidden md:block'>{( label)}</p>}
            <div 
              className={`rounded-full shadow-1 ${(current || next) ? 'bg-deactivatedDarkBlue' : 'bg-transparent border border-deactivatedDarkBlue'} ${(current) ? 'h-4 w-4 md:h-7 md:w-7' : 'h-2 w-2 md:h-4 md:w-4'}`} 
            ></div>
          </div>
        ))}
      </div> }
      <div className='min-w-max flex flex-col items-center xl:items-end pb-2 md:pb-4 lg:pb-0'>
        <p className='sm:text-lg lg:text-[20px] text-deactivatedDarkBlue md:text-darkBlue'>
          ¿Necesitás ayuda? Hacé click <a href="https://api.whatsapp.com/send/?phone=5492216234231&text&app_absent=0" target="_blank" rel="noreferrer" className='font-bold text-cyan'>aquí</a>
        </p>
        <p className='opacity-50 text-[16px] hidden md:block'>
          Sistema de turnos online del <span className='font-bold'>Laboratorio D’Agostino Bruno</span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
