
export const cleanPhone = (phone) => {
  const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
  let newNumber = ""
  phone.split("").forEach((letter, index) => {
    if(numbers.includes(letter)) newNumber = newNumber+letter
  })

  return newNumber
}
