import axios from "axios"
import { useEffect, useState, useCallback } from "react"
import { useDispatch} from "react-redux"
import authActions from "../../redux/actions/auth"
import { useParams } from "react-router-dom"

export const useAppoDetList = () => {
    const dispatch = useDispatch()
    const [errorState, setErrorState] = useState({ error: false, errorMessage: '' });
    const [auditoriasDetalle, setAuditoriasDetalle] = useState([])
    const [isSearching, setIsSearching] = useState(false)
    const params = useParams()
    let tx = params.tx
    
    const {REACT_APP_JAVA_BE_URL} = process.env
    
    const SearchAuditoriaDetalle = useCallback(() => {
        const url = `${REACT_APP_JAVA_BE_URL}/external/api/v1/faba/practicesByTransaction/${tx}`
        setIsSearching(true)
      
        axios.get(url)
          .then(res => {
            if(res.data.ok) {
              setAuditoriasDetalle(res.data.data)
              setErrorState({ error: false, errorMessage: '' });
            } else {
              setErrorState({ error: !res.data.ok, errorMessage: res.data.error });
            }
            setIsSearching(false)
          })
          .catch(error => {
            console.log(error); 
            setIsSearching(false)
            setErrorState({ error: !error.response.data.ok, errorMessage: error.response.data.error });
            if(error.response.status === 504) {
              setErrorState({ error: true, errorMessage: "En este momento no podemos acceder al detalle, vuelva a intentar en unos minutos" });
            }
            if(error.response && error.response.status === 401) dispatch(authActions.signOut(true))
          })
      }, [REACT_APP_JAVA_BE_URL, tx, dispatch])

  useEffect(() => {
    SearchAuditoriaDetalle()
  }, [SearchAuditoriaDetalle])

  return {
    auditoriasDetalle,
    errorState,
    isSearching,
    tx
  }
}