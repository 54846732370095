import { useState } from "react"
import PhoneInput from "react-phone-number-input"
import { CustomBirthDateInput, SelectInput, TextInput } from "../../../components/Inputs"
import { personalInputs } from "../../../data/inputs"
import {BoxAlert} from '../../../components/Alerts'
import { Navigate, useLocation } from "react-router-dom"

const Form = ({data}) => {
  const {documentValidation: {exist, searched}, user, resetDocument, openLogin} = data
  const [goLogin, setGoLogin] = useState(false)
  const location = useLocation()

  if(goLogin) return <Navigate to="/login" state={{from: location}} replace/>
  
  return (
    <div>
      {user && <p>Recordá que si iniciaste sesión solo podés sacar un turno para vos</p>}
      {!user && (
        <>
          {
            (searched && !exist) ? (
              <BoxAlert data={{type: "info", extraContainerStyles: "sm:col-span-2"}}>
                <p>
                  Si querés continuar con otro documento hacé <span onClick={resetDocument} className="!text-blue-900 !underline !cursor-pointer">clic aquí</span>.
                </p>
              </BoxAlert>
            ) : (
              <p className='z-70'>
                Validamos si tenés una cuenta en el portal
                {!searched && (
                  <>
                  <span>, de ser así{" "}</span>
                  <span onClick={openLogin} className="!z-50 !text-cyan !text-[15px] !cursor-pointer">
                    iniciá sesión{" "}
                  </span>
                  </>
                )}
              </p>
            )
          }
        </>
      )}
      <FormBeforeValidation data={data} />
      {(!exist && searched) && <FormAfterValidation data={data} />}
    </div>
  )
}

export default Form

const FormBeforeValidation = ({data: {appointment, validateDocument, documentValidation, updatePatient, openLogin, resetDocument}}) => {
  const documentTypes = [
    {value: "", label: "Seleccionar"},
    {value: "DNI", label: "DNI"},
    {value: "PAS", label: "Pasaporte"},
    {value: "CI", label: "Cédula"},
  ]

  const {exist, searched, error} = documentValidation
  const [errors, setErrors] = useState({idType: "", idNumber: ""})

  const onSubmit = () => {
    if (appointment?.patient?.idType && appointment?.patient?.idNumber) validateDocument({
      idType: appointment?.patient?.idType,
      idNumber: appointment?.patient?.idNumber
    })

    setErrors({
      idType: appointment?.patient?.idType ? "" : "Seleccioná un tipo de documento", 
      idNumber: appointment?.patient?.idNumber ? "" : "Completá un número de documento"
    })
  }

  const handleIdType = (value) => { updatePatient({idType: value}) }
  const handleIdNumber = (value)=> { updatePatient({idNumber: value}) }

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-6 mt-6'>
      <SelectInput data={{
        name: "idType", 
        value: appointment?.patient?.idType, 
        onChange: (e) => handleIdType(e.target.value), 
        customStyle: searched ? "bg-neutral-100 rounded-lg p-3 outline-none -mb-1" : "", 
        label: "Tipo de documento", 
        disabled: searched,
        errorText: errors.idType,
        options: documentTypes
      }} />

      <TextInput data={{
        type: "text", 
        disabled: searched,
        name: "idNumber", 
        value: appointment?.patient?.idNumber, 
        onChange: (e) => handleIdNumber(e.target.value), 
        placeholder: "Ej: 40328218", 
        customStyle: searched ? "bg-neutral-100 rounded-lg p-3 outline-none -mb-1" : "", 
        label: "Número de documento", 
        errorText: errors.idNumber
      }} />

      {!searched && <p></p>}

      {searched && exist && (
        <BoxAlert data={{
          type: "warning", 
          description:`${error} ${error.includes('cuenta activa') ? "iniciá sesión" : ""}`,
          extraContainerStyles: "sm:col-span-2 -mt-6 mb-2"
        }} />
      )}

      {searched && exist && (
        <>
        <button
          type="button"
          onClick={resetDocument}
          className='w-full text-cyan bg- border-cyan border py-4 px-6 400:px-12 rounded-lg flex-1 -mt-4'
        >
          Cambiar documento
        </button>
        <button
          type="button"
          onClick={openLogin}
          className='w-full text-white bg-cyan py-4 px-6 400:px-12 rounded-lg flex-1 -mt-4'
        >
          Iniciar sesión
        </button>
        </>
      )}
      
      {!searched && (
        <button
          type="button"
          onClick={onSubmit}
          className='w-full text-white bg-cyan shadow-6 py-4 px-6 400:px-12 rounded-lg flex-1 -mt-4'
        >
          Validar documento
        </button>
      )}
    </div>
  )
}

const FormAfterValidation = ({data: {formik, twilioError}}) => {
  const handlePhone = (value) => formik.setFieldValue("phone", value)

  const handleBirthDate = (value) => {
    formik.setFieldValue("birthDate", (value && value !== null) ? value : "")
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-6 pb-4'>
      {personalInputs.map((input, i) => (
        <div key={i} className={`${input.span}`}>
          <div>
            {input.options.length < 1
              ? (input?.name === "birthDate") 
                ? <CustomBirthDateInput data={{input, formik, handleBirthDate}} />
                : (
                <fieldset className={`h-14 flex flex-col gap-1 bg-green-10 ${input.span}`}>
                  <span className='text-xs opacity-70'>{input.title}</span>
                  <input 
                    className={`pb-1 outline-none bg-red-10 text-darkBlue placeholder:text-[#16325C99] min-h-[24px]`}
                    type={input.type}
                    placeholder={input.placeholder}
                    name={input.name}
                    defaultValue={input.name === "birthDate" ? new Date() : ""}
                    onChange={formik.handleChange}
                    value={formik.values[input.name]}
                  />
                  <div className={`flex flex-1 items-center text-xs text-red-400 bg-blue-10 border-t pt-1 ${formik.errors[input.name] ? 'border-error' : ''} opacity-80`}>
                    {formik.errors[input.name] && formik.errors[input.name]}
                  </div>
                </fieldset>
              )
              : (
                <fieldset className={`h-14 flex flex-col gap-1 bg-green-10 ${input.span}`}>
                  <span className='text-xs opacity-70'>{input.title}</span>
                  <select 
                    name={input.name}
                    onChange={formik.handleChange}
                    value={formik.values[input.name]}
                    className={`pb-1 outline-none w-full text-darkBlue`}
                  >
                    <option value="">Seleccionar</option>
                    {input.options.map((o, i) => <option key={i} value={o.value}>{o.label}</option>)}
                  </select>
                  <div className={`flex flex-1 items-center text-xs text-red-400 bg-blue-10 border-t ${formik.errors[input.name] ? 'border-error' : ''} opacity-80`}>
                    {formik.errors[input.name] && formik.errors[input.name]}
                  </div>
                </fieldset>
              )
            }
          </div>
        </div>
      ))}
      <fieldset className={`h-20 flex flex-col gap-1 bg-green-10`}>
        <span className='text-xs opacity-70'>Número celular</span>
        <div className='min-h-[24px]'>
          <PhoneInput
            international
            defaultCountry="AR"
            onChange={handlePhone}
            value={formik.values["phone"]}
            placeholder="Cód. de área + Núm. Celular"
            autoComplete={"false"}
            numberInputProps={{
              placeholder: "Cód. de área + Núm. Celular",
              style: {
                boxShadow: "0 0 #0000",
                border: "none",
                outline: "none",
                background: "transparent",
                marginLeft: "-25px",
              },
            }}
          />
        </div>
        <span className={`text-xs opacity-60  border-t pt-1 ${formik.errors["phone"] ? 'border-error' : ''}`}>
          Sin el 0 y sin el 15
        </span>
        <div className={`flex flex-1 items-center text-xs text-red-400 bg-blue-10 opacity-80`}>
          {formik.errors["phone"] ? formik.errors["phone"] : twilioError}
        </div>
      </fieldset>
    </div>
  )
}
