import axios from "axios"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"
import { MaterialIcon } from "../../components/Icons"
import pdfExample from './pdfExample.json'

export const usePatientsResultsList = () => {
  const [ results, setResults ] = useState([])
  const [filteredResults, setFilteredResults] = useState([])
  const [errorModal, setErrorModal] = useState({show: false, text: ""})
  const user = useSelector(state => state.auth.user)

  const BE_URL = process.env.REACT_APP_JAVA_BE_URL //114858
  const options = { headers: { Authorization: `Bearer ${user?.token}` } }

  const searchResults = () => {
    const url = `${BE_URL}/external/api/v1/patient/requests?idType=${user.idType}&idNumber=${user.idNumber}${user.mp ? `&mp=${user.mp}` : ""}${user.mn ? `&mn=${user.mn}` : ""}`

    axios.get(url, options)
      .then(res => {
        console.log(res.data);
        if(res.data){
          const newResults = res.data.map(result => {return {...result, fullName: `${result.apellido} ${result.nombre}`}})
          setResults(newResults)
          setFilteredResults(newResults)
        } 
      })
      .catch(error => {
        console.log(error);
      })
  }

  const downloadPDF = (number) => {
    const url = `${BE_URL}/external/api/v1/user/requests/${number}/pdf`
    
    axios.get(url, options)
      .then(res => {
        console.log(res.data);

        if(res.data.filedata) {
          const downloadLink = document.createElement("a");
          downloadLink.href = `data:application/pdf;base64,${res.data.filedata}`;
          downloadLink.download = res.data.filename;
          downloadLink.click()
        } else if (res.data.errorCode === 200) {
          const isAdministrative = res.data.errorMessage.includes('cuota permitida')

          setErrorModal({
            show: true, 
            text: "",
            title: `${isAdministrative ? 'Trámite administrativo pendiente de finalización' : 'Su solicitud está en proceso'}. Ante cualquier duda comuníquese por alguno de los siguientes medios:`,
            type: "info"
          })
        }
      })
      .catch(error => {
        console.log(error);

        toast({
          title: 'Error descargando el archivo',
          desc: 'Intentá nuevamente en unos minutos.',
          type: "error",
          icon: <MaterialIcon icon="error" />
        })
      })
  }

  const filterResults = (value) => {
    setFilteredResults(
      results.filter(result => result.fullName.replace(/ /g, "").toUpperCase().startsWith(value.replace(/ /g, "").toUpperCase()))
    )
  }
 
  useEffect(() => {
    searchResults()
  }, [])

  return {
    results: filteredResults, downloadPDF, user, filterResults, canSearch: results.length > 0, errorModal, setErrorModal
  }
}