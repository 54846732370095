import { MaterialIcon } from "./Icons"

export const BoxAlert = ({
  data: {type, title, description, sizeIcon, withoutIcon, extraContainerStyles}, children
}) => {

  const bg = type === "success" ? "bg-green-100 border-green-400 text-green-500"
  : type === "info" ? "bg-blue-100 border-blue-400 text-blue-500" 
  : type === "warning" ? "bg-orange-100 border-orange-400 text-orange-500"
  : "bg-red-100 border-red-400 text-red-500"

  return (
    <div className={`${extraContainerStyles} text-sm py-2 rounded px-3 border opacity-100 flex items-center justify-start gap-2 ${bg}`}>
      {!withoutIcon && <MaterialIcon icon={type} extrastyles={sizeIcon ? sizeIcon : "text-[18px] max-w-min"} />}
      <div>
        {title && <p>{title}</p>}
        {description && <p dangerouslySetInnerHTML={{__html: description}}></p>}
        {children && children}
      </div>
      
    </div>
  )
}
