import { SET_MODAL_USER_DATA } from "../case_types"

const defaultState = {
  isLoading: false, 
  showLogin: false, 
  isModalUserData: false
}

const othersReducer = (state = {...defaultState}, {type, payload}) => {
  switch (type) {
    case 'SET_SHOW_LOGIN':
      return { ...state, showLogin: payload }

    case 'SET_IS_LOADING':
      return { ...state, isLoading: payload }
    
    case SET_MODAL_USER_DATA:
      return { ...state, isModalUserData: payload }

    default:
      return state
  }
}

export default othersReducer
