import moment from "moment"

export const CancelModal = ({data: {cancelModal, cancelAppointment, setCancelModal}}) => {
  const preDate = moment(new Date(cancelModal.date))
  const date = `${preDate.format('dddd D')} de ${preDate.format('MMMM')} - ${cancelModal?.hour}:${cancelModal?.minute === 0 ? "00" : cancelModal?.minute} hs`

  return (
    <div className='bg-white rounded-lg shadow-2 p-2 py-4 400:p-5 flex flex-col items-center gap-4 justify-center text-[15px] text-center'>
      <h1 className='font-bold sm:text-2xl'>Estás por <span className='text-cyan'>cancelar</span> el siguiente turno</h1>
      <div className='text-white w-full py-5 bg-darkBlue rounded-lg px-4 400:px-5  flex flex-col gap-3'>
        <div className='flex flex-col items-start'>
          <span className='text-white opacity-50 uppercase text-sm'>Análisis</span>
          <p className='text-white text-lg'>{cancelModal.test}</p>
        </div>
        {cancelModal.isDomi ? (
          <div>
            <div className='flex flex-col items-start'>
              <span className='text-white opacity-50 uppercase text-sm'>FECHA Y HORA</span>
              <p className='text-white text-lg'>A definir.</p>
            </div>
            
          </div>
        ): (
          <div>
            <div className='flex flex-col items-start'>
              <span className='text-white opacity-50 uppercase text-sm'>FECHA Y HORA</span>
              <p className='text-white text-lg'>{date}</p>
            </div>
          </div>
        )}
        <div className='flex flex-col items-start'>
          <span className='text-white opacity-50 uppercase text-sm'>domicilio de extracción</span>
          <p className='text-white text-lg'>{cancelModal.address}</p>
        </div>
      </div>
      <div className='grid grid-cols-2 gap-3 justify-between w-full'>
        <button 
          onClick={() => setCancelModal(false)} 
          className='shadow-2 rounded-lg p-3 py- min-w-[100px]'
        >
          No
        </button>
        <button 
          onClick={cancelAppointment} 
          className='shadow-2 rounded-lg p-3 py- bg-cyan text-white'
        >
          Sí, cancelar
        </button>
      </div>
    </div>
  )
}
