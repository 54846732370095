import Footer from '../../components/Footer'
import { LoaderIcon } from '../../components/Icons'
import { useCancelAppointment } from './hook'

const CancelAppointment = () => {
  const {
    cancelAppointment,
    isLoading,
    appointment,
    navigate,
    isCancelled
  } = useCancelAppointment()

  return (
    <div>
      <div className='w-full h-[80vh] flex items-center justify-center'>
        {!isLoading 
          ? (isCancelled)
            ? (
              <div className='bg-white rounded-lg p-10 flex flex-col items-center gap-4'>
                <p className='font-bold text-green-600'>Turno cancelado correctamente</p>
                <button onClick={() => navigate("/agenda")} className='p-3 bg-cyan text-white rounded-lg '>Ir al inicio</button>
              </div>
            )
            : (
              <div className='sm:bg-white sm:shadow-2 rounded-lg px-3 py-5 sm:px-10 flex flex-col gap-6 items-center'>
                <h1 className='font-bold sm:text-2xl'>Estás por <span className='text-cyan'>cancelar</span> el siguiente turno</h1>
                <div className='bg-darkBlue text-white rounded-lg w-full p-5 space-y-4'>

                  <div className='text-white w-full bg-darkBlue rounded-lg sm:p-6 flex flex-col gap-3'>
                    <div className='flex flex-col items-start'>
                      <span className='text-white opacity-50 uppercase text-sm'>Análisis</span>
                      <p className='text-white text-lg'>{appointment.test}</p>
                    </div>
                    <div className='flex flex-col items-start'>
                      <span className='text-white opacity-50 uppercase text-sm'>FECHA Y HORA</span>
                      <p className='text-white text-lg'>{appointment.date}</p>
                    </div>
                    <div className='flex flex-col items-start'>
                      <span className='text-white opacity-50 uppercase text-sm'>domicilio de extracción</span>
                      <p className='text-white text-lg'>{appointment.address}</p>
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-2 gap-3 justify-between w-full'>
                  <button 
                    onClick={() => navigate("/agenda")} 
                    className='shadow-2 rounded-lg p-3 py- min-w-[100px]'
                  >
                    No
                  </button>
                  <button 
                    onClick={cancelAppointment} 
                    className='shadow-2 rounded-lg p-3 py- bg-cyan text-white'
                  >
                    Sí, cancelar
                  </button>
                </div>
              </div>
            )
          : (
            <div className=' w-[300px] h-[300px] bg-white rounded-lg shadow-2 flex items-center justify-center'>
              <LoaderIcon />
            </div>
          )
        }
      </div>
      <div className='w-full h-[10vh] flex items-end justify-end'>
        <Footer />
      </div>
    </div>
  )
}

export default CancelAppointment