const Document = ({data: {setDocument, document, searchUserByDocument, isPage, isInside}}) => {
  const condition = document.idNumber.length > 4
  return (
    <form onSubmit={searchUserByDocument}>
      <div className={`flex ${isInside ? 'flex-col sm:flex-row' : 'items-center justify-between'} gap-3`}>
       <div className='bg-white h-12 rounded-lg'>
        <select 
            value={document.idType} 
            className='px-1 outline-none h-12 rounded-lg bg-white' 
            onChange={(e) => setDocument({...document, idType: e.target.value})}
          >
            <option value="DNI">D.N.I.</option>
            <option value="CI">Cédula</option>
            <option value="PAS">Pasaporte</option>
          </select>
       </div>
        <input
          // typeNCß={(document?.idType && document?.idType === "DNI") ? 'number' : 'text'}
          className={`bg-white outline-none p-2 h-12 rounded-lg ${isPage ? 'w-full' : 'max-w-[187px]'}`}
          placeholder='38000000'
          onChange={(e) => setDocument({...document, idNumber: e.target.value})}
          value={document.idNumber}
          autoFocus
        />
      </div>
      <p className={`text-sm mt-2 text-error`}>{document.error}</p>

      <button
          className={`py-4 mt-2 w-full ${!condition ? 'bg-deactivatedCyan' : 'bg-cyan'} text-white rounded-lg px-10`}
          disabled={!condition}
          type='submit'
        >
          Ingresar
        </button>
    </form>
  )
}

export default Document
