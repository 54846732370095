import moment from 'moment';
import React, { useState } from 'react'

const AppointmentRow = ({data: {info, setCancelModal, showPending, appointments, index}}) => {
  const todayMs = new Date().getTime()

  const canDelete = info.status === "APPROVED" || info.status === "PENDING"
  const isPending = todayMs > info.dateMs

  const finalStatus = info.status === "APPROVED" && !isPending ? 'Confirmado' : info.status === "PENDING" ? "Por confirmar" : info.status === "CANCELLED" ? "Cancelado" : 'Finalizado'

  return (
    <tr className='border-t border-lightGrey'>
      {appointments[index].isDomi ? (
        <td className='pr-4 '>A definir.</td>
      ): (
        <td className='pr-4 '>{moment(info.date).format('MMMM DD, Y')}</td>
      )}
      {appointments[index].isDomi ? (
        <td className='pr-4 '>A definir.</td>
      ): (
        <td className='pr-4'>{info.range}</td>
      )}
      
      <td className='pr-4'>{info.address.replace(/ /g, "") !== "CalleN," ? info.address : info.address2}</td>
      <td className='text-deactivatedDarkBlue pr-4'>{info.test}</td>
      <td className='pr-4 flex-1'>
        <button className={`${!canDelete || isPending ? 'bg-deactivatedDarkBlue text-DarkBlue' : 'bg-deactivatedCyan text-cyan'} opacity-60 py-2 px-5 rounded-lg shadow-2 text-sm my-3 flex-1 w-full`} disabled>
          {finalStatus}
        </button>
      </td>
      {showPending && <td className=''>
        <button 
          className={`bg-cyan text-white py-2 px-5 rounded-lg shadow-8 text-sm my-3 ${!canDelete || isPending ? 'opacity-30' : ''}`} disabled={!canDelete || isPending}
           onClick={() => setCancelModal(info)}
        >
          Cancelar
        </button>
      </td>}
    </tr>
  )
}

const AppointmentsTable = ({data: {appointments, setCancelModal, showPending}}) => {

  return (
    <div className='hidden lg:block w-full flex-1 text-lg text-left !text-[15px]'>
      
      <table className='flex-1 w-full'>
        <thead className='text-deactivatedDarkBlue'>
          <tr>
            <th className='font-normal pb-3'>Fecha</th>
            <th className='font-normal pb-3'>Hora</th>
            <th className='font-normal pb-3'>Domicilio</th>
            <th className='font-normal pb-3'>Tipo de servicio</th>
            <th className='font-normal pb-3'>Estado</th>
            {showPending && <th className='font-normal pb-3'>Cancelar</th>}
          </tr>
        </thead>
        <tbody className='text-darkBlue'>
          {appointments.map((info, index) => (
            <AppointmentRow key={index} data={{info, setCancelModal, showPending, appointments, index}} />)
          )}
        </tbody>
      </table>
    </div>
  )
}

const AppointmentsList = ({data}) => {
  
  return (
    <div className='w-full flex-1'>
      <AppointmentsTable data={data} />
      <div className='lg:hidden'>
        {data.appointments.map((appo, index) => {
          const todayMs = new Date().getTime()
          const cantDelete = appo.status !== "APPROVED"
          const disabled = appo.dateMs > todayMs

          return (
            <div key={index} className='flex flex-col 500:flex-row 500:items-center justify-between flex-1 py-6 px-6 w-full gap-4 500:gap-6 border-b'>
              <div>
                <p className='mb-1 text-darkBlue'>{appo.test}</p>
                <p className='text-deactivatedDarkBlue'>
                  {appo.shop.toLowerCase().includes('domi') ? appo.shop : appo.address}
                </p>
              </div>
              <div className='flex items-center justify-between gap-2 '>
                <div className='500:text-right'>
                  <p className={`text-cyan mb-1 ${cantDelete ? 'opacity-30' : ''}`}>
                    {(appo.status === "APPROVED" && disabled) ? 'Confirmado' : appo.status === "PENDING" ? "Por confirmar" : appo.status === "CANCELLED" ? "Cancelado" : 'Finalizado'}
                  </p>
                  <p className='w-[127px] min-w-max text-darkBlue'>
                    {moment(appo.date).format('DD/MM/YY')} - {appo.range}
                  </p>
                </div>
                {data.showPending && (
                  <button 
                    disabled={cantDelete} 
                    onClick={() => data.setCancelModal(appo)} 
                    className={`${cantDelete ? 'bg-deactivatedDarkBlue' : 'bg-red-300'} rounded-full flex items-center justify-center p-1`}
                  >
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0.87299 1.06635C1.16588 0.773457 1.64075 0.773457 1.93365 1.06635L10.9336 10.0663C11.2265 10.3592 11.2265 10.8341 10.9336 11.127C10.6407 11.4199 10.1658 11.4199 9.87294 11.127L0.87299 2.12701C0.580097 1.83411 0.580097 1.35924 0.87299 1.06635Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M10.9337 1.06635C11.2265 1.35924 11.2265 1.83411 10.9337 2.12701L1.93365 11.127C1.64075 11.4199 1.16588 11.4199 0.87299 11.127C0.580097 10.8341 0.580097 10.3592 0.87299 10.0664L9.87299 1.06635C10.1659 0.773457 10.6408 0.773457 10.9337 1.06635Z" fill="white"/>
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AppointmentsList