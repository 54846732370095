import axios from "axios"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { shops, tests } from "../../data/appointments"
import authActions from "../../redux/actions/auth"


export const useAppoList = () => {
  const dispatch = useDispatch()
  const [appointments, setAppointments] = useState([])
  const [filteredAppointments, setFilteredAppointments] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [showPending, setShowPending] = useState(true)

  const {REACT_APP_JAVA_BE_URL} = process.env
  const user = useSelector(state => state.auth.user)

  const todayMs = new Date().getTime()

  const SearchAppointments = () => {
    const url = `${REACT_APP_JAVA_BE_URL}/external/api/v2/addressschedule/user/${user.id}/schedules?pageNumber=0&pageSize=20`
    setIsSearching(true)

    axios.get(url, { headers: { Authorization: `Bearer ${user?.token}` } })
      .then(res => {
        if(res.data && res.data.length > 0) {
          // console.log(res.data);

          const appointmentsResponse = res.data.map(appo => {
  
            var shop = shops.find(shop => appo.scheduleRange.schedulerEngine.shop.startsWith(shop.value))
            const test = tests.find(test => test.code.includes(appo.scheduleType))

            const dateAppo = appo.scheduleRange.schedulerEngine.date.split("T")[0]
            const hour = appo.scheduleRange.hour < 10 ? `0${appo.scheduleRange.hour}` : appo.scheduleRange.hour
            const minute = appo.scheduleRange.minute  < 10 ? `0${appo.scheduleRange.minute}` : appo.scheduleRange.minute

            const hourDomi = appo.scheduleRange.hour === 6 ? appo.scheduleRange.hour+4 : appo.scheduleRange.hour+2

            const date = `${dateAppo}T${hour}:${minute}:00-03:00`

            // const shopSudor = shops.find(sudor => sudor.value === "SUC13")

            const isDomi = appo.scheduleRange.schedulerEngine.shop.includes('DOMI')

            return {
              id: appo.id,
              status: appo.status,
              tokenId: appo.tokenId,
              scheduled: appo.date,
              hour,
              minute,
              range: isDomi ? `${hour}:${minute} a ${hourDomi < 10 ? `0${hourDomi}` : hourDomi}:${minute} hs` : `${moment(date).format('HH:mm')} hs`,
              otherId: appo.scheduleRange.id,
              isDomi,
              date,
              dateMs: new Date(date).getTime(),
              //shop: (test.value === "test-sudor")?shopSudor.label: shop.label,
              shop: shop.label,
              // address: (test.value != "test-sudor")? (appo.scheduleRange.schedulerEngine.shop.toLowerCase().includes("domi") 
              // ? (`Calle ${appo.user?.streetName ? appo.user?.streetName : ""} N ${appo.user?.streetNumber ? appo.user?.streetNumber : ""},  ${appo.user?.city ? appo.user?.city : ""}`) 
              // : `${shop.place.address} ${shop.place.city}`):
              // (
              //   `${shopSudor.place.address} ${shopSudor.place.city}`
              // ),
              address: (appo.scheduleRange.schedulerEngine.shop.toLowerCase().includes("domi") 
              ? 
              (`Calle ${appo.user?.streetName ? appo.user?.streetName : ""} N ${appo.user?.streetNumber ? appo.user?.streetNumber : ""},  ${appo.user?.city ? appo.user?.city : ""}`) 
              : `${shop.place.address} ${shop.place.city}`),
              test: test?.name || "Extracción / muestra",
              address2: appo?.user?.address.replace(/ /g, "") && appo?.user?.address.replace(/ /g, "") !== "" ? appo?.user?.address : "Mi domicilio"
            } 
          })

          setAppointments(appointmentsResponse)
          setFilteredAppointments(appointmentsResponse.filter(appo => appo.dateMs >= todayMs && (appo.status === "APPROVED" || appo.status === "PENDING")))
        } else throw new Error("without-appointments")
        setIsSearching(false)
      })
      .catch(error => {
        console.log(error); 
        setIsSearching(false)
        setAppointments([])

        if(error.response && error.response.status === 401) dispatch(authActions.signOut(true))
      })
  }

  const cancelAppointment = () => {
    const url = `${REACT_APP_JAVA_BE_URL}/external/api/v2/addressschedule/cancel2/${cancelModal.tokenId}`
    setIsCancelling(true)

    axios.post(url, { headers: { Authorization: `Bearer ${user?.token}` } })
      .then(res => {
        // console.log(res.data);
        setIsCancelling(false)
        setCancelModal(false)
        SearchAppointments()
      })
      .catch(error => {
        console.log(error);
        setIsCancelling(false)

        if(error.response && error.response.status === 401) dispatch(authActions.signOut(true))
      })
  }

  const filterAppointments = (filterByPendings) => {
    if(filterByPendings) {
      setFilteredAppointments(appointments.filter(appo => appo.dateMs >= todayMs && (appo.status === "APPROVED" || appo.status === "PENDING")).sort((a, b) => {
        return a.dateMs - b.dateMs
      }))
    } else {
      setFilteredAppointments(appointments.filter(appo => (appo.dateMs < todayMs && appo.status === "APPROVED") || appo.status === "CANCELLED").sort((a, b) => {
        return b.dateMs - a.dateMs
      }))
    }
  }

  useEffect(() => {
    filterAppointments(showPending)
  }, [appointments, showPending])

  useEffect(() => {
    SearchAppointments()
  }, [])

  return {
    appointments: filteredAppointments,
    isSearching,
    user,
    cancelAppointment,
    cancelModal,
    setCancelModal,
    showPending,
    setShowPending
  }
}