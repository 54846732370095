import Footer from '../../components/Footer'
import { LoaderIcon } from '../../components/Icons'
import AuditoriasList from './AuditoriasList'
import { useAppoList } from './hook'

const Auditorias = () => {
  const {    
    auditorias,
    isSearching,
    user,
  } = useAppoList()

  return (
    <>
      <div className='mx-auto max-w-max p-10 px-3 min-h-[78vh]'>
        <h1 className='font-bold text-xl sm:text-[28px] xl:text-4xl mb-11'>
          Hola {user.name}, estas son <span className='text-cyan'>tus auditorias</span>
        </h1>

        <div className='flex items-center gap-2 mb-4 -mt-4'>
          <button
            // onClick={() => setShowPending(true)}
            // className={`${showPending ? 'bg-cyan text-white' : 'text-white bg-deactivatedCyan'} w-[130px] py-2 px-4 rounded-full`}
            className='bg-cyan text-white w-[130px] py-2 px-4 rounded-full ' disabled
          >
            Resultados
          </button>
        </div>

        <div className='bg-white shadow-5 rounded-lg lg:py-8 lg:px-8 lg:min-w-[900px] lg:max-w-min flex items-center'>
          {isSearching 
            ? <LoaderIcon />
            : auditorias.length > 0
            ? <AuditoriasList data={{auditorias}} />
            : <p>No tenés auditorias pendientes</p>
          }
        </div>
      </div>
      <div className='w-full h-[10vh] flex items-end justify-end'>
        <Footer />
      </div>
    </>
  )
}

export default Auditorias
