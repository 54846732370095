import axios from 'axios'
import toast from 'react-hot-toast'
 
const authActions = {
    authUser: (user, flag) => {
        return (dispatch, getState) => {
            // console.log('entre al action', user);
            dispatch({type: 'AUTH_USER', payload: user})
            // axios.post(`http://localhost:4000/api/user/${flag}`, { ...user })
            //     .then(res => { if (res.data) dispatch({ type: 'AUTH_USER', payload: res.data.user }) })
            //     .catch(error => console.log(error))
        }
    },
    loginUserLS: (user) => {
        return (dispatch, getState) => {
            dispatch({type: 'AUTH_USER', payload: user})
            // axios.get('http://localhost:4000/api/verifytoken', {
            //     headers: { Authorization: 'Bearer ' + token }
            // })
            //     .then(res => dispatch({ type: 'AUTH_USER_LS', payload: res.data.user }))
            //     .catch(error => dispatch({ type: 'SIGN_OUT', payload: error }))
        }
    },
    signOut: (is401) => (dispatch, getState) => {
        dispatch({ type: 'SIGN_OUT', payload: null })
        if(is401) toast({
            type: "error",
            title: "Tu sesión ha expirado",
            desc: 'Inicia sesión nuevamente para ingresar a la plataforma'
        })
    } 
}
 
export default authActions