import { MaterialIcon } from '../../components/Icons'
import { EmptyModal } from '../../components/Modals'
import { useResultsList } from './hook'
import { ResultsTable } from './table'
import {BsWhatsapp} from 'react-icons/bs'

const Results = () => {
  const MAIL_SECRETARIA = process.env.REACT_APP_MAIL_SECRETARIA
  const { results, downloadPDF, user, errorModal, setErrorModal } = useResultsList()

  return (
    <>
      {errorModal.show && (
        <EmptyModal>
          <div className="bg-white p-3 400:p-5 rounded-lg flex flex-col items-center max-w-[500px]">
            <div className='w-full flex items-center justify-end'>
              <button onClick={() => setErrorModal({...errorModal, show: false})}>
                <svg className='text-darkBlue' width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 13L1 1" stroke="grey" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M13 1L1 13" stroke="grey" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </div>
            <MaterialIcon icon="info" extrastyles="text-[50px] 400:text-[70px] text-blue-400" />
            <div  className='py-6 400:text-center flex flex-col items-center gap-2'>
              <p className='pl-2 400:pl-0 text-xl sm:text-2xl font-bold text-blue-400'>{errorModal.title}</p> 
              <p className='pl-2 400:pl-0 text-blue-300 400:max-w-[400px]'>{errorModal.text}</p>
            </div>
            <div className='w-full pl-2 400:pl-6 mb-5'>
              <div className='flex flex-col gap-2 text-[15px] text-blue-400 w-full max-w-[400px]'>
                <a 
                  className='flex items-center gap-1 justify-start' 
                  href="https://wa.me/5492216234231" 
                  target="_blank"
                >
                  <span className='text-[19px]'><BsWhatsapp /></span>
                  <span className='underline'>+54 9 221 623 4231</span>
                </a>
                <a 
                  className='flex items-center gap-1 justify-start' 
                  href="tel:5492216234231"
                >
                  <MaterialIcon icon="phone" extrastyles="text-[20px]" />
                  <span className='underline'>+54 9 221 425 4900</span>
                </a>
                <a 
                  className='flex items-center gap-1 justify-start' 
                  href={`mailto:${MAIL_SECRETARIA}`}
                  target="_blank"
                >
                  <MaterialIcon icon="mail" extrastyles="text-[20px]" />
                  <span className='underline'>secretaria@dagostino-bruno.com.ar</span>
                </a>
                <a 
                  className='flex items-center gap-1 justify-start' 
                  href="https://dagostino-bruno.com.ar" 
                  target="_blank"
                >
                  <MaterialIcon icon="public" extrastyles="text-[20px]" />
                  <span className='underline'>www.dagostino-bruno.com.ar</span>
                </a>
              </div>
            </div>
          </div>
        </EmptyModal>
      )}
      <div className='mx-auto max-w-max pt-10 pb-10'>
        <h3 className='font-bold text-xl sm:text-[28px] xl:text-4xl mb-4'>
          Hola {user.name}, estos son <span className='text-cyan'>tus resultados</span>
        </h3>

        <ResultsTable data={{results, downloadPDF}} role="user" />
      </div>
    </>
  )
}

export default Results
