import { 
  ExtraccionesIcon, 
  ExamenMicologicoIcon, 
  //TestSudorIcon, 
  CovidAnticuerposIcon, 
  HspdCovidIcon, 
  HspdNoCovidIcon, 
  //HspdVirusRespirtorioIcon
} from '../components/Icons'

export const tests = [
  {
    name: "Extracción / muestra", 
    value: "extraccion-muestra", 
    code: "TEST_EXTRACCION",
    icon: <ExtraccionesIcon />,
    shops: [
      {disabled: false, value: "SUC14"},
      {disabled: false, value: "SUC13"}, 
      {disabled: false,  value: "SUCGORINA"},
      {disabled: false, value: "DOMI_EXTRACCION"}
    ]
  },
  {
    name: "Exámen Micológico", 
    value: "examen-micologico", 
    code: "TEST_MICOLOGICO",
    icon: <ExamenMicologicoIcon />,
    cols: "xl:col-span-3" ,
    shops: [
      {disabled: true, value: "SUC14MICO"},
      {disabled: false, value: "SUC13MICO"},
      {disabled: true, value: "SUCGORINA"},
      {disabled: true, value: "DOMI"}
    ]
  },
  // {
  //   name: "Test del sudor", 
  //   value: "test-sudor", 
  //   code: "TEST_SUDOR",
  //   icon: <TestSudorIcon />,
  //   shops: [
  //     {disabled: true, value: "SUC14SUDOR"},
  //     {disabled: false, value: "SUC13SUDOR"},
  //     {disabled: true, value: "DOMI"}
  //   ]
  // },
  // {
  //   name: "COVID Anticuerpos", 
  //   value: "covid-anticuerpos", 
  //   code: "TEST_COVIDANTICUERPOS",
  //   icon: <CovidAnticuerposIcon />,
  //   shops: [
  //     {disabled: false, value: "SUC14"},
  //     {disabled: false, value: "SUC13"},
  //     {disabled: false, value: "DOMI_EXTRACCION"}
  //   ]
  // },
  // {
  //   name: "Hisopado COVID", 
  //   value: "hisopado-covid", 
  //   code: "TEST_COVIDHISOPADOS",
  //   icon: <HspdCovidIcon />,
  //   shops: [
  //     {disabled: true, value: "SUC14CO"},
  //     {disabled: false, value: "SUC13CO"},
  //     {disabled: false, value: "DOMI_EXTRACCION"}
  //   ]
  // },
  {
    name: "Hisopados",
    value: "otros-hisopados",
    code: "TEST_OTROSHISOPADOS",
    icon: <HspdNoCovidIcon />,
    shops: [
      {disabled: false, value: "SUC14HISRESP"},
      {disabled: false, value: "SUC13HISRESP"},
      {disabled: true,  value: "SUCGORINA"},
      {disabled: true, value: "DOMI"}
    ]
  }
]

export const shops = [
  {label: "Sede 13", value: "SUC13",  place: {address: "Av. 13 N° 215", city: "- La Plata"}},
  {label: "Sede 14", value: "SUC14", place:{address: "Av. 14 N° 280", city: "- La Plata"}},
  {label: "Mi domicilio", value: "DOMI", place: {address: "En la plata", city: ""}},
  {label: "Sede Gorina", value: "SUCGORINA", place: {address: "Calle 485 N° 4685 Esq. 138", city:"Gorina"}}
]