import Footer from '../../components/Footer'
import { LoaderIcon } from '../../components/Icons'
import { EmptyModal } from '../../components/Modals'
import AppointmentsList from './AppointmentsList'
import { CancelModal } from './CancelModal'
import { useAppoList } from './useAppoList'

const Appointments = () => {
  const {    
    appointments,
    isSearching,
    user,
    cancelModal, 
    cancelAppointment,
    setCancelModal,
    setShowPending,
    showPending
  } = useAppoList()

  return (
    <>
      {cancelModal && <EmptyModal><CancelModal data={{cancelModal, cancelAppointment, setCancelModal}} /></EmptyModal>}
      <div className='mx-auto max-w-max p-10 px-3 min-h-[78vh]'>
        <h1 className='font-bold text-xl sm:text-[28px] xl:text-4xl mb-11'>
          Hola {user.name}, estos son <span className='text-cyan'>tus turnos</span>
        </h1>

        <div className='flex items-center gap-2 mb-4 -mt-4'>
          <button
            onClick={() => setShowPending(true)}
            className={`${showPending ? 'bg-cyan text-white' : 'text-white bg-deactivatedCyan'} w-[130px] py-2 px-4 rounded-full`}
          >
            Pendientes
          </button>
          <button
            onClick={() => setShowPending(false)}
            className={`${!showPending ? 'bg-cyan text-white' : 'text-white bg-deactivatedCyan'} w-[130px] py-2 px-4 rounded-full`}
          >
            Previos
          </button>
        </div>

        <div className='bg-white shadow-5 rounded-lg lg:py-8 lg:px-8 lg:min-w-[900px] lg:max-w-min flex items-center'>
          {isSearching 
            ? <LoaderIcon />
            : appointments.length > 0
            ? <AppointmentsList data={{appointments, setCancelModal, showPending}} />
            : <p>No tenés turnos pendientes</p>
          }
        </div>
      </div>≈
      <div className='w-full h-[10vh] flex items-end justify-end'>
        <Footer />
      </div>
    </>
  )
}

export default Appointments
