// import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import scheduleActions from '../../../redux/actions/schedule'
import {tests} from '../../../data/appointments'

const TestCard = ({test: {name, icon, value, code, shops}, appointment,}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClick = () => {
    const activeShops = shops.filter(shop => !shop.disabled)

    const newAppointment = {
      test: {name, value, shops, code},
      date: false,
      hour: false,
      shop: false
      // shop: activeShops.length > 0 ? activeShops[0] : undefined
    }

    if (!appointment?.test || appointment?.test?.name !== name) {
      dispatch(scheduleActions.updateAppointment(newAppointment))
    }
    navigate(`/agenda/calendario`)
  }

  return (
    <button 
      onClick={handleClick}
      className={`test-card ${appointment?.test?.code === code ? "bg-darkBlue text-white" : 'bg-white text-darkBlue'}`}
    >
      {icon && icon }
      <p className='min-w-max font-bold xs:text-sm sm:text-[16px] md:text-2xl'>{name ? name : ''}</p>
    </button>
  )
}

const ChooseExamn = () => {
  const appointment = useSelector(state => state.schedule)
  const user = useSelector(state => state.auth.user)

  return (
    <div className='flex items-center justify-center lg:pt-10'>
      <div className='w-full max-w-[1299px]'>
        <h1 className='text-xl sm:text-[28px] xl:text-4xl font-bold mb-10 lg:mb-20'>
          Hola{user ? ` ${user?.name}` : ""}, ¿<span className='text-cyan'>qué estudio </span> tenés que realizar?
        </h1>
        <div className='flex flex-col md:flex-row md:items-center w-full gap-6 xl:gap-10 2xl:gap-14'>
          <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 w-full'>
            {tests.map( (t, i) => <TestCard test={t} appointment={appointment}  key={i} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseExamn
