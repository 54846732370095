import DivOutside from "../DivOutside";
import { InstructivoCard } from "./InstructivoCard";
export const Instructivos = () => {
    return (
        <DivOutside>
            <h1 className='text-sm sm:text-[20px] xl:text-2xl font-bold m-10 lg:m-10'>
                Hola, ¿<span className='text-cyan'>qué instructivo </span> tenés que descargar?
            </h1>

            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 w-full lg:p-5 lg:px-5  px-3'>
              <InstructivoCard name="Coprocultivo" urlPdf={'coprocultivo.pdf'}></InstructivoCard>
              <InstructivoCard name="Curva Glucemia" urlPdf={'curva_glucemia.pdf'}></InstructivoCard>
              <InstructivoCard name="Micológico" urlPdf={'micologico.pdf'}></InstructivoCard>
              <InstructivoCard name="Orina Completa" urlPdf={'orina_completa.pdf'}></InstructivoCard>
              <InstructivoCard name="Orina 24 hs" urlPdf={'orina_24.pdf'}></InstructivoCard>
              <InstructivoCard name="Prolactina" urlPdf={'prolactina.pdf'}></InstructivoCard>
              <InstructivoCard name="PSA" urlPdf={'PSA.pdf'}></InstructivoCard>
              <InstructivoCard name="SOMF" urlPdf={'SOMF.pdf'}></InstructivoCard>
              <InstructivoCard name="Urocultivo (adultos)" urlPdf={'urocultivo_adultos.pdf'}></InstructivoCard>
              <InstructivoCard name="Urocultivo (niños)" urlPdf={'urocultivo_ninos.pdf'} alone="1"></InstructivoCard>
            </div>
          
        </DivOutside>
    )
}
