import "react-datepicker/dist/react-datepicker.css";
import { MaterialIcon } from './Icons'
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
registerLocale("es", es);



// TYPES: text, number, phone
export const TextInput = ({data: {type, name, value, onChange, placeholder, customStyle, label, errorText, disabled}}) => {
  return (
    <label className='h-20 flex flex-col gap-1 bg-green-10'>
      <span className='text-xs opacity-70'>{label}</span>
      <input
        name={name}
        disabled={disabled}
        type={type}
        value={value}
        onChange={onChange}
        className={customStyle ? customStyle : "pb-1 outline-none bg-red-10 text-darkBlue placeholder:text-[#16325C99] min-h-[24px]"}
        placeholder={placeholder}
      />
      <div className={`flex flex-1 items-center text-xs text-red-400 bg-blue-10 opacity-80 ${disabled ? '' : 'border-t'}`}>
        {errorText && errorText}
      </div>
    </label>
  )
}

export const SelectInput = ({data: {name, value, onChange, customStyle, options, label, errorText, disabled}}) => {
  return (
    <label className='h-20 flex flex-col gap-1 bg-green-10'>
      <span className='text-xs opacity-70'>{label}</span>
      <select
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={customStyle ? customStyle : "pb-1 outline-none bg-red-10 text-darkBlue placeholder:text-[#16325C99] min-h-[24px]"}
      >
        {options.map((option, index) => (
          <option key={index} value={option?.value}>{option?.label}</option>
        ))}
      </select>
      <div className={`flex flex-1 items-center text-xs text-red-400 bg-blue-10 opacity-80 ${disabled ? '' : 'border-t'}`}>
        {errorText && errorText}
      </div>
    </label>
  )
}

export const DateInput = ({data: {name, value, onChange, customStyle, label, errorText, disabled}}) => {
  return (
    <label className='h-20 flex flex-col gap-1 bg-green-10'>
      <span className='text-xs opacity-70'>{label}</span>
      <input
        name={name}
        type="date"
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={customStyle}
      />
      <div className="flex flex-1 items-center text-xs text-red-400 bg-blue-10 opacity-80">
        {errorText && errorText}
      </div>
    </label>
  )
}

export const CustomBirthDateInput = ({data: {input, formik, handleBirthDate, disabled}}) => {
  return (
    <fieldset className={`h-14 flex flex-col gap-1 bg-green-10 ${input.span}`}>
      <span className='text-xs opacity-70'>{input.title}</span>
      <DatePicker
        selected={formik.values[input.name] ? new Date(formik.values[input.name]) : ""}
        onChange={handleBirthDate}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale={"es"}
        dateFormat="dd/MM/yyyy"
        maxDate={new Date()}
        strictParsing
        disabled={disabled}
        placeholderText="dd/mm/aaaa"
        className="!outline-none !w-full !rounded-none !border-none !min-h-[25px] !pb-1"
        calendarClassName="!text-darkBlue"
        renderCustomHeader={(props) => <CustomHeaderDatePicker data={{...props}} /> }
      />
      <div className={`flex flex-1 items-center text-xs text-red-400 bg-blue-10 border-t pt-1 ${formik.errors[input.name] ? 'border-error' : ''} opacity-80`}>
        {formik.errors[input.name] && formik.errors[input.name]}
      </div>
    </fieldset>
  )
}

const CustomHeaderDatePicker = ({data: {
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}}) => {

  const range = (min, max) => Array.from(new Array((max)-min), (x,i) => i+min)

  const getYear = (date) => 1900 + (new Date(date).getYear())

  const getMonth = (date) => new Date(date).getMonth()

  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ]

  return (
    <div className="flex items-center justify-between px-4 gap-1 ">
      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <MaterialIcon icon="chevron_left" />
      </button>
    
      <label className="flex itens-center gap-1 border rounded p-1 font-bold border-darkBlue">
        <select
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          className="outline-none "
        >
          {months.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        {/* <MaterialIcon icon="expand_more" extrastyles="text-[17px]" /> */}
      </label>

      <select
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
        className="outline-none border rounded p-1 font-bold border-darkBlue"
      >
        {years.map((option) => ( <option key={option} value={option}>{option} </option> ))}
      </select>

      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <MaterialIcon icon="chevron_right" />
      </button>
    </div>
)}