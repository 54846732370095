import { useState } from "react"
import VerificationInput from "react-verification-input";

const VerifyCodePopup = ({ data: { validateCode, setStep, codeError } }) => {
  const [code, setCode] = useState("")
  const condition = code.toString().length > 4

  return (
    <div>
      <div className='flex flex-col gap-2'>
        <div>
          <div className='flex items-center justify-center'>
            <VerificationInput
              classNames={{
                container: "verificationInput_container",
                character: "verificationInput_character",

              }}
              length={5}
              autoFocus={true}
              validChars="0-9"
              inputProps={{ inputMode: "numeric" }}
              onChange={(e) => setCode(e)}
            />
          </div>


          {codeError && <div>
            <p className='text-error text-sm mt-2'>
              El código ingresado es inválido, verificálo o <span className='text-white underline cursor-pointer' onClick={() => setStep(1)}>pedí un código nuevo</span>
            </p>
          </div>}
        </div>
        <button
          className={`${condition ? 'bg-cyan' : 'bg-deactivatedCyan'} ' text-white py-4 rounded mt-3`}
          onClick={() => validateCode(code)}
          disabled={!condition}
        >
          Iniciar sesión
        </button>
      </div>
    </div>
  )
}

export default VerifyCodePopup
