import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'

import './index.css'
import App from './App'
import mainReducer from './redux/reducers/main'
import { BrowserRouter } from 'react-router-dom'

const globalStore = createStore(mainReducer, applyMiddleware(thunk))
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <StrictMode>
    <Provider store={globalStore}>
      <BrowserRouter>
          <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>
)

