import { combineReducers } from "redux"
import authReducer from './auth'
import othersReducer from "./others"
import scheduleReducer from "./schedule"
 
const mainReducer = combineReducers({
   auth: authReducer,
   schedule: scheduleReducer,
   others: othersReducer
})
 
export default mainReducer