const authReducer = (state = { user: false, token: null }, action) => {
  switch (action.type) {
      case 'AUTH_USER':
          // console.log('entré al reducer', action.payload);
          localStorage.setItem('user', JSON.stringify(action.payload))
          return { ...state, user: action.payload }
        //   , token: action.payload 

      case 'AUTH_USER_LS':
          return { ...state, user: action.payload}

      case 'SIGN_OUT':
          localStorage.removeItem('user')
          return { ...state, user: false }
      default:
          return state
  }
}

export default authReducer
