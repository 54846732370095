import Footer from '../../components/Footer'
import { LoaderIcon } from '../../components/Icons'
import AuditoriasDetalleList from './AuditoriasDetalleList'
import { useAppoDetList } from './hook'
import { Link } from 'react-router-dom'
import { ArrowIcon } from '../../components/Icons'

const AuditoriasDetalle = () => {
  const {    
    auditoriasDetalle,
    errorState,
    isSearching,
    tx
  } = useAppoDetList()
  let size = {w: 12, h:16}
  return (
    <>
      <div className='mx-auto max-w-max p-10 px-3 min-h-[78vh]'>
        <h1 className='font-bold text-xl sm:text-[28px] xl:text-4xl mb-11'>
          Detalle de Prácticas (*)<span className='text-cyan'>{tx}</span>
        </h1>

        <div className=' mb-4 -mt-4'>
          <Link
            className='bg-cyan text-white  py-2 px-4 rounded-full flex items-center justify-start max-w-max gap-2' disabled
            to='/auditorias'
          >
            <ArrowIcon left size={size}/>Volver
          </Link>
        </div>
        <div className='bg-white shadow-5 rounded-lg lg:py-8 lg:px-8 lg:min-w-[900px] lg:max-w-min flex items-center'>
          {isSearching 
            ? <LoaderIcon />
            : auditoriasDetalle
            ? <AuditoriasDetalleList data={{ auditoriasDetalle, errorState }} />
            : <p>El detalle solicitado no fue encontrado.</p>
          }
        </div>
      </div>
      {auditoriasDetalle.mutualId === '1' || auditoriasDetalle.mutualId === '4' || auditoriasDetalle.mutualId === '419' || auditoriasDetalle.mutualId === '978' || auditoriasDetalle.mutualId === '754' || auditoriasDetalle.mutualId === '601' ? (
        <span className='px-4 font-bold text-sm sm:text-[28px] xl:text-xl mb-11 block text-center'>
          De acuerdo a normativa FABA - IOMA, vigente desde el 1/3/2024, el afiliado a IOMA abonará en el laboratorio un bono de $3000 por atención. Por tal motivo deberá adicionar el mencionado importe junto a las prácticas rechazadas.
        </span>
      ) : null}
      <span className='px-4 text-cyan font-bold text-sm sm:text-[14px] xl:text-md mb-11 block text-center'>
        (*)Información suministrada por la Federación Bioquímica de la Provincia de Buenos Aires.
      </span>          
      <span className='px-4 text-cyan font-bold text-sm sm:text-[14px] xl:text-md mb-11 block text-center'>
        (*)Tenga en cuenta que el monto final puede diferir ya que dicha autorización no contempla las prácticas no nomencladas por la obra social, bonos o copagos.
      </span>          
      <div>

      </div>
      <div className='w-full h-[10vh] flex items-end justify-end'>
        <Footer />
      </div>
    </>
  )
}

export default AuditoriasDetalle
