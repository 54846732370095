export const modals_info = {
  extracciones: {
    left: [
      "Cortisoles",
      "17-OH Progesterona",
      "ACTH",
      "Actividad de Renina Plasmática",
      "Adrenalina y Noradrenalina séricas",
      "Aldosterona",
      "Hormona de crecimiento (GH / STH)",
    ],
    right: [
      "Prolactina",
      "IGF-1 (Somatomedina C)",
      "Beta Cross Laps",
      "DHEA-S",
      "Dopamina sérica",
      "Serotonina sérica",
      "Curva de Tolerancia Oral a la Glucosa",
    ]
  },
  fridays: {
    title: "Las siguientes prácticas no se realizan los días viernes ni previos a feriados",
    left: [
      "Apo E Genotipificación",
      "Fragilidad Osmótica",
      "Gen Braf Exon 15 (V600e)",
      "Cariotipo Alta Resolución y Bandeo G"
    ],
    right: [
      "Test DHR (Estallido Respiratorio)",
      "HIV DNA Proviral",
      "*PCR para: Chlamydia, Chikungunya, Epstein Barr, Enterovirus, Parvovirus B19, Poliomavirus BK, Poliomavirus JC, Toxoplasmosis, Verotoxina, HTLV 1 Y 2, Herpes Simplex 1 Y 2"
    ]
  },
  martes: {
    title: "Las siguientes prácticas no se realizan los días martes ni previos a feriados",
    left: [
      
      "Cariotipo Alta Resolución y Bandeo G"
    ],
    right: [
      
    ]
  },
  sabados: {
    left: [
      "Calcio Iónico",
      "Cariotipos",
      "Test DHR (Estallido Respiratorio)",
      "Ciclosporina A",
      "Fragilidad Osmótica",
      "Linfocitos CDs (incluye CD2, CD3, CD4, CD5, CD8, CD19, CD20, CD16/56)",
      "Crioaglutininas"
    ],
    right: [
      "HIV - Carga viral",
      "DNA Proviral (transmisión vertical) ",
      "Inmunofenotipificación",
      "Verotoxina",
      "Glucosa 6 Fosfato Deshidrogenasa",
      "Apo E Genotipificación",
      "*PCR para: Chlamydia, Zika, Chicungunya, Adenovirus, Epstein Barr, Enterovirus, Parvovirus, Polioma BK."
    ]
  },
  micologico: {
    test: "examen-micologico",
    title: "Para realizar el EXÁMEN MICOLÓGICO se deben tomar las siguientes precauciones",
    details: "Debe suspender  cualquier tratamiento local con cremas/lociones  durante los 10 días previos y realizar 2-3 baños diarios de agua tibia con sal durante los 3 días previos a la toma de la  muestra. No cortarse las uñas afectadas. El día de la toma de la muestra, cepillar la uña con abundante agua y jabón, en lo posible poco tiempo antes de concurrir al laboratorio, no colocar talco, cremas ni esmalte y utilizar CALZADO CERRADO y MEDIAS luego del lavado."
  },
  // sudor: {
  //   test: "test-del-sudor",
  //   title: "Para realizar el TEST DEL SUDOR se deben tomar las siguientes precauciones",
  //   details: "El paciente no debe estar en ayunas. Debe permanecer en el laboratorio mientras se realiza el estudio, aproximadamente 1 hora. Se recomienda beber abundante líquido el día anterior de la realización de la prueba, y evitar la ingesta excesiva de alimentos salados. Traer  una bebida del gusto del paciente, la cual beberá en el momento que se lo indique la profesional (durante la prueba). Traer abrigo."
  // },
  extraccionesSangre:{
    left: [
      "Calcio Iónico",
      "Linfocitos CDs (incluye CD2, CD3, CD4, CD5, CD8, CD19, CD20, CD16/56)",
      "Cariotipo",
      "ADN Fetal",
      "Fragilidad Osmótica",
    ],
    right: [
      "Polioma BK",
      "Test DHR (Estallido Respiratorio)",
      "HIV - Proviral",
      "HLA",
      "Paneles Genéticos",
      "Ferremia y Saturación de Transferrina",
    ]
  },
}

// La prueba requiere permanecer aproximadamente 2 hs en el Laboratorio. Preferentemente el niño no debe tener fiebre, vómitos o diarrea cinco días antes de realizar el test, con el objeto de favorecer la obtención de la muestra. Debe beber líquido (jugo, leche, agua, etc.) en forma normal el día del examen. En ningún caso el niño debe estar en ayunas al momento de realizar el test. No aplicar crema, polvos, o talco en brazos y piernas el día del examen. Debe venir aseado. --- "La prueba requiere permanecer aproximadamente 2 hs en el laboratorio. Preferentemente el niño no debe tener fiebre, vómitos o diarrea cinco días antes de realizar el test, con el objeto de favorecer la obtención de la muestra. Debe beber líquido (jugo, leche, agua, etc.) en forma normal el día del examen. En ningún caso el niño debe estar en ayunas al momento de realizar el test. No aplicar crema, polvos, o talco en brazos y piernas el día del examen. Debe venir aseado.