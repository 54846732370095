import { useDispatch } from "react-redux"
import authActions from "../redux/actions/auth"
import scheduleActions from "../redux/actions/schedule"
import { parseJwt } from "./jwt"

export const validateJWTExpiration = (user, dispatch) => {
  const jwtData = parseJwt(user?.token)
  const expirationMs = jwtData?.exp * 1000
  const nowMs = new Date().getTime()

  console.log(new Date(expirationMs), expirationMs-nowMs);

  if(nowMs >= expirationMs) {
    dispatch(authActions.signOut(true))
  } else {
    setTimeout(() => {
      dispatch(authActions.signOut(true))
    }, expirationMs-nowMs)
  }
}

export const getStorage = (dispatch) => {
  const user = localStorage.getItem('user')
  if(user) {
    dispatch(authActions.loginUserLS( JSON.parse(user)))
    validateJWTExpiration(JSON.parse(user), dispatch)
  }

  const appointment = sessionStorage.getItem('appointment')
  if(appointment) dispatch(scheduleActions.updateAppointmentFromSS(JSON.parse(appointment)))


  // console.log('storages',  JSON.parse(user), JSON.parse(appointment));
}
