
const RangeButton = ({data: {range, updateAppointment, appointment, shortRange,gap}}) => {
  const disabled = range.assigned === range.maxAvailable
  const active = appointment?.hour?.id === range?.id
  const disabledStyle = 'bg-deactivatedDarkBlue text-darkBlue opacity-30 cursor-not-allowed'
  const activeStyle = 'bg-darkBlue text-white cursor-pointer'
  const chosenStyle = 'bg-white cursor-pointer'

  const style = `shadow-4 min-w-[70px] max-h-[30px] rounded-[6px] font-semibold text-sm md:text-md flex items-center justify-center py-4 ${disabled ? disabledStyle : active ? activeStyle : chosenStyle} ${shortRange ? 'font-bold text-lg py-8' : ''}`

  return (
    <div
      disabled={disabled}
      onClick={() => updateAppointment({hour: range})}
      className={style}
    >
      <p>
        {shortRange 
          ? `De ${range.hour}:${range.minute} am a ${parseInt(range.hour)+gap}:${range.minute} am` 
          : `${range.hour}:${range.minute}`
        }
      </p>
    </div>
  )
}

const Ranges = ({data: {ranges, appointment, updateAppointment}}) => {
  const shortRange = appointment?.shop?.value.includes('DOMI') || appointment?.shop?.value.includes('GORINA') 
  const gap=(appointment?.shop?.value.includes('GORINA')?3:4)
  return (
    <>
      {ranges?.length > 0
        ? (
          <div className='w-full h-full'>
            <div className={`p-4 grid ${shortRange ? 'grid-cols-1' : 'grid-cols-3 sm:grid-cols-4 custom-scrollbar overflow-y-scroll'} gap-4 w-full`}>
              {ranges.map((range, i) => (
                <RangeButton data={{range, updateAppointment, appointment, shortRange,gap}} key={i} />
              ))}
            </div>
          </div>
        )
        : (
          <div className='flex flex-col items-center gap-8 py-10'>
            <p className='text-center w-[80%]'>No hay turnos disponibles para el día seleccionado.</p>
            <img src="/assets/icons/today_black_48dp.svg" alt="" className='opacity-30' />
          </div>
        )
      }
    </>
  )
}

export default Ranges
