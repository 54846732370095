const Loader = () => (
  <div className="bg-white opacity-50 overflow-hidden w-[100%] h-[100%] fixed loader-index flex justify-center z-[400000000000]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: "auto",
        background: "#fff",
        display: "block",
      }}
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M31 50A19 19 0 0 0 69 50A19 22.2 0 0 1 31 50"
        fill="blue"
        stroke="none"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="0.8928571428571428s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 51.6;360 50 51.6"
        ></animateTransform>
      </path>
    </svg>
  </div>
);

export default Loader