const defaultState = {}

// este reducer es solo para el turno que se está agendando
const scheduleReducer = (state = {...defaultState}, { type, payload } ) => {
  switch (type) {
    case 'UPDATE_APPOINTMENT':
      sessionStorage.setItem('appointment', JSON.stringify({...state, ...payload}))
      return {...state, ...payload}

    case 'UPDATE_APPOINTMENT_KEY':
      // console.log('reducer new key', payload);

      const newData = {...state, ...payload}
      sessionStorage.setItem('appointment', JSON.stringify(newData))
      return newData

    case 'EMPTY_SS': 
      sessionStorage.removeItem('appointment')
      return defaultState

    case 'APPOINTMENT_FROM_SS': 
      return {...state, ...payload}

    default: return state
  }
}

export default scheduleReducer