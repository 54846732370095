import axios from "axios";
import * as Yup from 'yup';
import { useState } from "react"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { lookupPhone } from "../../libs/twilio"
import authActions from "../../redux/actions/auth";
import { useNavigate } from "react-router-dom";

export const useProfessionalRegister = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const BE_URL = process.env.REACT_APP_JAVA_BE_URL

  const [isSaving, setIsSaving] = useState(false)
  const [twilioError, setTwilioError] = useState("")
  const [msg, setMsg] = useState({text: "", color: ""})

  const patientSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Escribe más de 2 carácteres como mínimo').max(50, 'Too Long!').required('Este campo es obligatorio'),
    surname: Yup.string().min(2, 'Escribe más de 2 carácteres como mínimo').max(50, 'Too Long!').required('Este campo es obligatorio'),
    idType: Yup.string().required('Este campo es obligatorio').oneOf(['DNI', 'PAS', 'CI']),
    phone: Yup.number().required('Este campo es obligatorio').min(1000000000, 'Este campo es obligatorio'),
    gender: Yup.string().required('Este campo es obligatorio'),
    specialty: Yup.string().required('Este campo es obligatorio'),
    mail: Yup.string().email('Verifica que sea un email válido').required('Este campo es obligatorio'),
    idNumber: Yup.string().when('idType', {
        is: "DNI",
        then: schema => schema.min(6, 'Tu DNI debe ser mayor o igual a 400000').max(8, 'Tu DNI debe ser menor o igual a 99000000'),
      }).required('Este campo es obligatorio'),
    birthDate: Yup.string().required('Este campo es obligatorio')//.max(new Date(), 'La fecha declarada no puede ser posterior a hoy').min(new Date("01-01-1900"), "La fecha declarada debe ser posterior a 01/01/1900"),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema: patientSchema,

    onSubmit: async values => {
      setIsSaving(true)
  
      const newPhone = values.phone.includes('+') ? values.phone.split('+')[1] : values.phone
      const output = await lookupPhone(newPhone)
  
      if(!values.mp && !values.mn) {
        setMsg({text: "Debés ingresar una de las dos matrículas.", color: "text-error"})
        setIsSaving(false)
        return null
      } else if(!output?.mobile) {
        if(values.mp || values.mn) setMsg({text: "", color: "text-error"})
        setTwilioError("Por favor ingresá un número móvil válido")
        setIsSaving(false)
        return null
      } else {
        setTwilioError("")
        const url = `${BE_URL}/external/api/v1/users/`
        const payload = { 
          ...values, 
          phone: newPhone, 
          role: "USER_ROL_PROFESSIONAL", 
          mp: values.mp ? values.mp : undefined,
          mn: values.mn ? values.mn : undefined,
        }
  
        axios.post(url, payload)
          .then(res => {
            dispatch(authActions.authUser({ 
              ...res.data,
              birthDate: res.data.birthDate.split("T")[0]
            }))
            setMsg({text: "Registro exitoso", color: "text-green-400"})
            setIsSaving(false)
            navigate('/agenda')
          })
          .catch(error => {
            console.log(error);
            setIsSaving(false)
            setMsg({text: "Hubo un error guardando la información o el usuario ya existe", color: "text-error"})
          })
      }
    }
  })

  return {
    formik,
    twilioError,
    isSaving,
    msg,
    user
  }
}
