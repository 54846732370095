import imageCompression from 'browser-image-compression';

export const compressFile= async (file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };
  
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile // write your own logic
  } catch (error) {
    console.log(error);
  }
}

export const compressFiles = async (files) => {
  return Array.from(files).map((f)=> {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
  
    return imageCompression(f, options);
  });
}